/*
	~ Page Not Found View
*/

import React, { Component } from 'react';

import { 
	Icon, 
	Paper, 
	Button,

} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import { ROUTES, ROUTE_OBJS } from 'settings/routes';

import Styles from './styles';


class PageNotFoundView extends Component {

	//rN Lifecycle ----------------------

	render() {
		const { classes } = this.props;

		return (
			<div className={classes.container}>
				<Paper
					square
					elevation={4}
					classes={{ root: classes.centralizedContent }}>

					<h1 className={classes.errorTitle}>
						<Icon classes={{ root: classes.errorIcon }}>
							do_not_disturb
						</Icon>
						Page Not Found
					</h1>

					<h3 className={classes.errorSubTitle}>Whoops!</h3>

					<p style={{fontStyle: 'italic'}}>
						It looks like you have ventured too far!
					</p>

					<Button
						size={'large'}
						variant={'raised'}
						color={'secondary'}
						classes={{ root: classes.homeButton }}
						onClick={this.__handleHomeButtonOnClick}>
						
						Go Back Home
					</Button>
				</Paper>
			</div>
		);
	}



	//Functionality ---------------------



	//Events ----------------------------

	__handleHomeButtonOnClick = event => {
		const { navigateTo } = this.props;

		if(navigateTo) navigateTo(ROUTE_OBJS[ROUTES.HOME].path);
	};
	
}


export default withStyles(Styles)(PageNotFoundView);

