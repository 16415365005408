/*
	~ Nav Link View - Stateless
*/

import React from 'react';
import Styles from './styles';
import includeClasses from 'classnames';

import { withStyles } from '@material-ui/core/styles';


export default withStyles(Styles)(
	({
		ref,
		onClick,
		classes,
		children,
		className,
		navigateTo,
		route = null,
		isActive = false,
	}) => {
		const __handleLinkOnClick = event => {
			event.preventDefault();
			event.stopPropagation();
			
			if (onClick) {
				onClick(event, route);
			
			} else if (route) {
				navigateTo(route);
			}
		};

		return (
			<a
				ref={ref}
				href={'/'}
				className={className}
				onClick={__handleLinkOnClick}>
				
				<span 
					className={
						includeClasses({ 
							[classes.link]: true,
							[classes.activeLink]: isActive,
						})
					}>

					{ children }
				</span>
			</a>
		);
	}
);