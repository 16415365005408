/*
	~ Email Dialog View
*/

import React, { Component } from 'react';

import Styles from './styles';
import PropTypes from 'prop-types';
import SubmitButton from 'atoms/_SubmitButton';

import { Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import { 
	renderInputField,
	renderDropdownField,

} from 'utils/formRenderingUtilities';

import { 
	maxChars,
	required,
	getInitialEmailDialogFormData,

} from 'utils/formUtilities';

import {
	OTHER,
	CUSTOM,
	REPAIR,

} from 'constants/dialog';

import { 
	Grid,
	Dialog,
	Typography,
	IconButton,
	DialogTitle,
	DialogActions,
	DialogContent,
	withMobileDialog,

} from '@material-ui/core';

const mainContentCharacterLimit = maxChars(500);


class EmailDialogView extends Component {

	static propTypes = {
		isOpen: PropTypes.bool,
		color: PropTypes.string,
		title: PropTypes.string,
		maxWidth: PropTypes.string,
		emailType: PropTypes.string,
		productsLoaded: PropTypes.bool,
		onSubmit: PropTypes.func.isRequired,
		closeEmailDialog: PropTypes.func.isRequired,
		initialType: PropTypes.oneOf([
			CUSTOM.value,
			REPAIR.value,
			OTHER.value,
		]),
	};

	static defaultProps = {
		title: null,
		isOpen: false,
		maxWidth: 'md',
		emailType: null,
		color: 'secondary',
		productsLoaded: false,
		initialType: CUSTOM.value,
	};


	//rN Lifecycle ----------------------

	componentDidMount() {
		const { initialize, initialType } = this.props;

		initialize( getInitialEmailDialogFormData(initialType) );
	}


	componentWillUnmount() {
		const { destroy } = this.props;
		if (destroy) destroy();
	}

	
	render() {
		const { 
			color,
			title,
			isOpen,
			classes,
			maxWidth,
			fullScreen,
			handleSubmit,

		} = this.props;

		return (
			<Dialog
				open={isOpen}
				fullWidth={true}
				maxWidth={maxWidth}
				fullScreen={fullScreen}
				disableRestoreFocus={true}
				onClose={this.__handleDialogClose}
				aria-labelledby={'start-job-dialog'}>
			
				{title && (
					<div className={classes.dialogHeader}>
						<DialogTitle 
							id={'start-job-dialog'} 
							classes={{root: classes.dialogHeaderTitle}}>

							{ title }
						</DialogTitle>

						<IconButton 
							aria-label={'Close'}
							classes={{root: classes.dialogHeaderCloseButton}}
							onClick={this.__handleDialogClose} color={color}>
							
							<Close />
						</IconButton>
					</div>
				)}

				<form onSubmit={handleSubmit}>
					<DialogContent>
						<div className={classes.emailContent}>
							{ this.renderEmailContent() }
						</div>
					</DialogContent>
					

					<DialogActions classes={{root: classes.dialogFooter}}>
						<SubmitButton onClick={this.__handleDialogSendClick}>
							ASK
						</SubmitButton>
					</DialogActions>
				</form>
			</Dialog>
		);
	}


	renderEmailContent() {
		const { classes } = this.props;
		
		const {
			inputLabel,
			bodyContent,

		} = this.getContentByType();

		return (
			<React.Fragment>
				{bodyContent}

				{
					renderInputField({
						inputLabel,
						isMultiline: true,
						fieldId: 'mainContentInputId',
						fieldName: 'mainContentInput',
						validation: [required, mainContentCharacterLimit],
						inputProps: {
							rows: 1,
							rowsMax: 10,
							fullWidth: true,
							placeholder: `Don't be afraid to type, this box will grow`,
							classes: {
								input: classes.dialogTextInput,
								underline: classes.dialogTextInputUnderline,
							},
						},
					})
				}
			</React.Fragment>
		);
	}


	renderCustomTypeContent() {
		const { 
			classes, 
			product,
			areProductsLoaded,

		} = this.props;

		if (areProductsLoaded) {
			return (
				<div className={classes.dropdownRow}>
					<Grid container spacing={16}>
						<Grid item xs={12} md={4}>
							{product && product['id_9'] && (
								this.renderEmailDropdown({
									label: 'Type',
									fieldName: 'type',
									items: product['id_9'].tags,
									helperText: 'What type of product?',
								})
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							{product && product['id_10'] && (
								this.renderEmailDropdown({
									label: 'Material',
									fieldName: 'material',
									items: product['id_10'].tags,
									helperText: 'What type of material?',
								})
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							{product && product['id_11'] && (
								this.renderEmailDropdown({
									label: 'Designer',
									fieldName: 'designer',
									items: product['id_11'].tags,
									helperText: 'Preferred Designer?',
								})
							)}
						</Grid>
					</Grid>
				</div>
			);

		} else {
			return null;
		}
	}


	renderRepairTypeContent() {
		const { classes } = this.props;

		return (
			<div className={classes.dropdownRow}>
				<Grid container spacing={16}>
					<Grid item xs={12} md={5}>
						{
							this.renderEmailDropdown({
								label: 'Repair Types',
								fieldName: 'repair-type',
								helperText: 'In-house Repair?',
								items: [
									`Professional cleaning`,
									`Ring sizing`,
									`Laser welding and torch soldering`,
									`Chain repairs and new clasps`,
									`Diamond and gemstone replacement`,
									`Re-tipping of claws and crown replacement`,
									`Re-shanking of rings (restoring thinning backs of rings)`,
									`Rebuilding of all types of gem settings & worn parts`,
								],
							})
						}
					</Grid>
					<Grid item xs={12} md={2}>
						<div className={classes.divider}>
							<Typography variant={'title'}>OR</Typography>
						</div>
					</Grid>
					<Grid item xs={12} md={5}>
						{
							this.renderEmailDropdown({
								label: 'Outside Services',
								helperText: 'Outside Service?',
								fieldName: 'outside-service-type',
								items: [
									`Accredited jewellery appraisals`,
									`Diamond and gemstone re-cutting`,
									`Specialized antique restorations`,
									`Hand Engraving`,
									`Pearl and Bead Restringing`,
								],
							})
						}
					</Grid>
				</Grid>
			</div>
		);
	}


	renderEmailDropdown = ({
		label,
		items,
		fieldName,
		helperText,
		includeEmptyDefault = true,
	}) => (
		renderDropdownField({
			label,
			items,
			fieldName,
			helperText,
			validation: [],
			includeEmptyDefault,
			fieldId: `${fieldName}Id`,
		})
	);



	//Functionality ---------------------

	getContentByType() {
		const { emailType } = this.props;
		let bodyContent = null;
		let inputLabel = '';

		switch(emailType) {
			case CUSTOM.value:
				bodyContent = this.renderCustomTypeContent();
				inputLabel = 'Please describe your vision *';
				break;

			case REPAIR.value:
				bodyContent = this.renderRepairTypeContent();
				inputLabel = 'Do you need something fixed? Tell us about it. *';
				break;

			case OTHER.value:
				inputLabel = 'Is there something you would like to ask us?';
				break;

			default:
				bodyContent = null;
				break;
		}

		return {
			inputLabel,
			bodyContent,
		};
	}



	//Events ----------------------------

	__handleDialogClose = event => { 
		const { closeEmailDialog } = this.props;
		if (closeEmailDialog) closeEmailDialog(event);
	};


	__handleDialogSendClick = event => {
		const { form, sendForm } = this.props;

		event.preventDefault();
		event.stopPropagation();

		if (sendForm) sendForm(form);
	};

}


export default withMobileDialog()(withStyles(Styles)(EmailDialogView));

