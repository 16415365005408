/*
	STORE STRUCTURE
	===================
	
	├─ App
	├─ Router
	├─ Form
	├─ Notification Center
	└─ Email Dialog
*/

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import { 
	appPersistConfig,

} from 'constants/localStorageKeys';

import app from './App';
import emailDialog from './CustomerEmail';
import notificationCenter from './NotificationCenter';


export default combineReducers({
	emailDialog,
	form: formReducer,
	notificationCenter,
	router: routerReducer,
	app: persistReducer(appPersistConfig, app),
});