/*
	~ Email Dialog Styles
*/


export default theme => ({
	dialogHeader: {
		width: '100%',
		position: 'relative',
	},
	dialogHeaderTitle: {
		paddingTop: 30,
		paddingBottom: 40,
	},
	dialogHeaderCloseButton: {
		top: 15,
		right: 25,
		position: 'absolute',
	},
	constainer: {},
	header: {},
	emailContent: {
		marginTop: 25,
	},
	
	dialogTextInput: {
		paddingLeft: 0,
		paddingRight: 0,
		fontSize: theme.typography.fontSize,
		fontFamily: theme.typography.fontFamily,
	},
	dropdownRow: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
	},
	divider: {
		width: '100%',
		height: '100%',
		display: 'flex',
		paddingBottom: 45,
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	},

	dialogFooter: {
		justifyContent: 'center',
	},
});

