/*
	~ Home Page Connector
*/

import React from 'react';
import HomePageView from './index.js';

import { connect } from 'react-redux';
import { navigateTo } from 'reducers/App/actions';
import { ScrollPositionConsumer } from 'atoms/_Scroll';


const ScrollProvidingConnection = props => (
	<ScrollPositionConsumer>
		{
			({ windowPosition }) => ( <HomePageView windowPosition={windowPosition} { ...props } /> )
		}
	</ScrollPositionConsumer>
);

const mapStateToProps = state => {
	return {}
};

const mapDispatchToProps = dispatch => {
	return {
		navigateTo,
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(ScrollProvidingConnection);

