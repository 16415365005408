//====================================================
/* ~ REDUX STORE PROVIDER ------------------------- */
//====================================================

import React from 'react';
import configureStore from './configureStore';

//Entry point for React application logic
import App from 'views/App/appContainer';

import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { CircularProgress } from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';

//Get browser history
const history = createBrowserHistory();
const { store, persistor } = configureStore(history);


//Hook into Store and Launch Application
export default () => (
	<Provider store={store}>
		<PersistGate 
			persistor={persistor}
			loading={(
				<CircularProgress 
					size={100}
					style={{
						top: '50%',
						left: '50%',
						position: 'fixed'
					}} />
			)}>

			<App history={history} />
		</PersistGate>
	</Provider>
);

//------

