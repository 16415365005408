/*
	~ REDUX Reducer User Action Key Constants

	FORMAT: const name + '_ACTION', all uppercase
*/

// User Actions Keys
export const ADD_MESSAGE_TO_NOTIFICATION_CENTER = 'ADD_MESSAGE_TO_NOTIFICATION_CENTER_ACTION';
export const REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER = 'REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER_ACTION';

// Message Display Types
export const MESSAGE_TYPE_ERROR = 'error';
export const MESSAGE_TYPE_SUCCESS = 'success';

