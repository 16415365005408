/*
	~ Home Page Styles
*/

import oceanfloorImage from 'images/oceanfloor.png'; 

import { SERVICES_HEIGHT } from './index';
import { getRGBAColor } from 'helpers/styleHelpers';

const HomePageVideoHeight = 110;

const bubbleText = theme => ({
	marginTop: 0,
	fontSize: '1.5em',
	textAlign: 'center',
	fontStyle: 'italic',
	fontFamily: 'Open Sans',
});

const waveContainer = {
	left: 0,
	height: 300,
	width: '100%',
	overflow: 'hidden',
	position: 'absolute',
};

const miniBubble = theme => ({
	width: 30,
	height: 30,
	borderRadius: 15,
	border: `3px solid ${theme.palette.primary.light}`,
	backgroundColor: theme.palette.primary.contrastText,
});

const bubbleColumn = {
	width: '100%',
	height: 'auto',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	justifyContent: 'center',
};

const servicesColumn = {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
};


export default theme => ({
	container: {
		width: '100vw',
		height: 'auto',
		display: 'flex',
		alignItems: 'center',
		position: 'relative',
		flexDirection: 'column',
		justifyContent: 'center',
		background: theme.palette.defaultBackgroundColor,
	},

	titleIcon: {
		marginTop: 5,
		marginRight: 15,
		fontSize: '2.25rem',
		verticalAlign: 'middle',
		color: theme.palette.secondary.main,
	},

	video: {
		width: '100%',
		minHeight: 700,
		objectFit: 'cover',
		height: `${HomePageVideoHeight}vh`,
		zIndex: theme.zIndex.mobileStepper,
	},

	videoOverlay: {
		width: '100%',
		top: 0, left: 0,
		display: 'flex',
		position: 'absolute',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		height: `${HomePageVideoHeight}vh`,
		zIndex: theme.zIndex.mobileStepper,
	},
	companyTitle: {
		marginTop: 0,
		marginLeft: 25,
    	fontSize: '4em',
		paddingLeft: 20,
		paddingRight: 20,
		letterSpacing: 6,
		marginBottom: 10,
    	textAlign: 'center',
		fontFamily: 'vermin',
		color: theme.palette.primary.light,
		backgroundColor: getRGBAColor('#000', 0.15),
	},
	whoAreWeButton: {
		marginBottom: '15vh',
	},

	waveContainer,
	topWaveContainer: {
		...waveContainer,
		top: 0,
		transform: 'translateY(-98%)',
	},
	bottomWaveContainer: {
		...waveContainer,
		bottom: 0,
		transform: 'translateY(98%)',
	},
	wave: {
		width: '100%',
		height: '100%',
	},
	primaryColor: {
		fill: theme.palette.primary.main,
	},
	secondaryColor: {
		fill: 'rgb(14, 50, 75)',
	},

	// Bubbles
	bubblesContainer: {
		zIndex: 9999,
		marginTop: -80,
		paddingTop: 25,
		paddingBottom: 25,

		'& > div': {
			zIndex: 99,
		},
	},
	bubbleColumn,
	pushRightBubbleColumn: {
		...bubbleColumn,
		alignItems: 'flex-end',
	},
	pushLeftBubbleColumn: {
		...bubbleColumn,
		alignItems: 'flex-start',
	},
	doubleBubble: {
		width: 55,
		height: 75,
		position: 'relative',

		'& div:first-child': {
			...miniBubble(theme),
			position: 'absolute',
			top: 15,
			left: 20,
		},
		'& div:nth-child(2)': {
			...miniBubble(theme),
			position: 'absolute',
			top: 40,
			left: 25,
		},
	},
	tripleBubble: {
		width: 55,
		height: 75,
		position: 'relative',

		'& div:first-child': {
			...miniBubble(theme),
			position: 'absolute',
			top: 15,
			left: 20,
		},
		'& div:nth-child(2)': {
			...miniBubble(theme),
			position: 'absolute',
			top: 40,
			left: 10,
		},
		'& div:nth-child(3)': {
			...miniBubble(theme),
			position: 'absolute',
		}
	},


	companyDescription: {
		marginTop: 15,
		padding: '0 20%',
		marginBottom: 50,
		fontSize: '1.5em',
		textAlign: 'center',
		fontFamily: 'Open Sans',
	},

	companyTagline: {
		marginTop: 0,
		paddingLeft: 20,
		marginBottom: 40,
		paddingRight: 20,
		fontSize: '1.5em',
		textAlign: 'center',
		fontStyle: 'italic',
		fontFamily: 'Open Sans',
		color: theme.palette.primary.contrastText,
		backgroundColor: getRGBAColor('#000', 0.15),
	},

	descriptionContainer: {
		width: '100%',
		position: 'relative',
		zIndex: theme.zIndex.mobileStepper,
		backgroundColor: theme.palette.primary.main,
	},

	siteLogo: {
		width: '45%',
		height: '45%',
		marginTop: 10,
		marginBottom: 20,
	},
	iconBox: {
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',	
	},
	
	moneyIcon: {
		fontSize: 100,
		marginBottom: 10,
	},
	moneyTagline: {
		...bubbleText(theme),
		fontSize: '1.5em',	
	},

	workIcon: {
		fontSize: 110,
		marginBottom: 10,
		color: theme.palette.primary.main,
	},
	clockIcon: {
		fontSize: 45,
		position: 'absolute',
		color: theme.palette.primary.contrastText,
	},
	experienceTagline: {
		...bubbleText(theme),
		fontSize: '1.5em',
		color: theme.palette.primary.main,
	},

	servicesContainer: {
		width: '100%',
		display: 'flex',
		paddingLeft: '10%',
		paddingRight: '10%',
		alignItems: 'center',
		position: 'relative',
		flexDirection: 'column',
		justifyContent: 'center',
		paddingTop: (SERVICES_HEIGHT / 2.25),
		paddingBottom: (SERVICES_HEIGHT / 4),
		background: `linear-gradient(180deg, #000a12 20%, #08253E 65%, #082536 90%)`,
	},

	processContainer: {
		width: '105%',
		display: 'flex',
		marginTop: '-1%',
		position: 'relative',
		flexDirection: 'column',
	},
	processColumn: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	rockContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	rockBottom: {
		width: '100%',
		marginTop: -10,
	},
	topRock: {},
	bottomRock: {
		zIndex: 999,
	},
	processTextContent: {
		zIndex: 999,
		width: '100%',
		marginTop: -5,
		paddingTop: 150,
		display: 'flex',
		paddingLeft: '10%',
		paddingBottom: 150,
		paddingRight: '10%',
		flexDirection: 'column',
		backgroundColor: 'rgb(14, 50, 75)',
	},
	sectionTitle: {
		width: '100%',
		textAlign: 'right',
		fontSize: '3.25rem',
		fontFamily: 'vermin',
		color: theme.palette.primary.contrastText,
	},

	aboutUs: {
		width: '100%',
		paddingTop: 200,
		paddingLeft: '10%',
		paddingRight: '10%',
		paddingBottom: (SERVICES_HEIGHT / 4),
	},
	aboutUsTitle: {
		width: '100%',
		textAlign: 'right',
		fontSize: '3.25rem',
		fontFamily: 'vermin',
		color: theme.palette.secondary.contrastText,
	},
	aboutUsVisionTitle: {
		color: theme.palette.primary.dark,
	},
	aboutUsVisionSubTitle: {
		color: theme.palette.primary.dark,
	},

	emptySpaceContainer: {
		width: '100%',
		height: '85vh',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		backgroundColor: theme.palette.primary.dark,
	},

	backToTopButton: {
		right: 25,
		bottom: 10,
		position: 'fixed',
		zIndex: 9999999999999,
		backgroundColor: theme.palette.secondary.light,
	},

	treasureHunt: {
		position: 'relative',
		backgroundColor: theme.palette.secondary.dark,
	},
	searchlightBox: {
		width: '100vw',
		height: 'auto',
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		backgroundColor: '#ffea99',
		'clip-path': 'circle(15% at 15% 25%)',
	},
	animatedSearchLight: {
		animationDuration: '12s',
	    animationTimingFunction: 'ease',
	    animationName: 'lightSearchAction',
	    animationIterationCount: 'infinite',
	},
	sandyBottom: {
		width: '100%',
		minHeight: 250,
		height: '90vh',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundImage: `url(${oceanfloorImage})`,
	},

	footer: {
		width: '100%',
		height: '55vh',
		display: 'flex',
		paddingTop: '25vh',
		paddingLeft: '10%',
		paddingRight: '10%',
		alignItems: 'flex-end',
		paddingBottom: '10vh',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		backgroundColor: theme.palette.primary.dark,
	},
	footerTitle: {
		width: '100%',
		fontSize: '3rem',
		paddingRight: 50,
		textAlign: 'right',
		fontFamily: 'vermin',
		color: theme.palette.primary.contrastText,
		// borderBottom: `2px solid ${theme.palette.primary.contrastText}`,
	},

	'@keyframes lightSearchAction': {
		'0%': {
			'clip-path': 'circle(15% at 15% 25%)',
		},
		'10%': {
			'clip-path': 'circle(15% at 50% 25%)',
		},
		'20%': {
			'clip-path': 'circle(15% at 15% 40%)',
		},
		'30%': {
			'clip-path': 'circle(15% at 50% 35%)',
		},
		'40%': {
			'clip-path': 'circle(15% at 85% 25%)',
		},
		'50%': {
			'clip-path': 'circle(15% at 50% 50%)',
		},
		'60%': {
			'clip-path': 'circle(15% at 85% 65%)',
		},
		'70%': {
			'clip-path': 'circle(15% at 50% 75%)',
		},
		'80%': {
			'clip-path': 'circle(15% at 15% 80%)',
		},
		'90%': {
			'clip-path': 'circle(15% at 100% 65%)',
		},
		'95%': {
			'clip-path': 'circle(15% at 85% 35%)',
		},
		'100%': {
			'clip-path': 'circle(15% at 15% 25%)',
		},
	},
});
