/*
	~ Nav Link Connector
*/

import { connect } from 'react-redux';
import { navigateTo } from 'reducers/App/actions';

import NavLinkView from './index.js';


const mapDispatchToProps = {
	navigateTo,
};


export default connect(null, mapDispatchToProps)(NavLinkView);

