/*
	~ Rock Bottom Facing Texture Component
*/

import React from 'react';
import PropTypes from 'prop-types';


const RockBottom = ({
	flip,
	className,

}) => {
	return (
		<svg 
			className={className}
			viewBox={'0 0 503.50207 233.62708'}
			preserveAspectRatio={'xMaxYMid meet'}>

		    <g transform='translate(-2.249 -2.249)' id='layer3' stroke='#173249' strokeOpacity='0.338'>
		        <g 
		        	id={'g3138'}
		        	stroke={'#173249'}
		        	strokeWidth={'0.265'}
		        	strokeLinecap={'round'}
		        	strokeOpacity={'0.338'}
		        	strokeLinejoin={'round'}
		        	transform={`${flip ? 'none' : 'scale(1 -1) rotate(.018 753625.485 .032)'}`}>

		            <path d='M 137.83345,2.38125 195.83093,13.108232 239.2355,2.38125 Z' id='path1926' fill='#616e76' />
		            <path d='M 440.54291,2.38125 474.21887,23.585197 505.61874,2.38125 Z' id='path1928' fill='#425e6f' />
		            <path d='M 2.38125,16.475824 38.302273,2.38125 H 2.38125 Z' id='path1930' fill='#74797a' />
		            <path d='m 474.21887,23.585197 31.39987,-7.109373 V 2.38125 Z' id='path1932' fill='#3f5d6f' />
		            <path d='M 293.49121,34.43634 332.77985,2.38125 H 274.034 Z' id='path1934' fill='#546873' />
		            <path d='M 50.275951,41.171532 76.094185,2.38125 H 38.302273 Z' id='path1936' fill='#6e7579' />
		            <path d='M 150.92966,38.552292 195.83093,13.108232 137.83345,2.38125 Z' id='path1938' fill='#606e75' />
		            <path d='M 375.06189,2.38125 420.33734,43.042419 440.54291,2.38125 Z' id='path1940' fill='#496170' />
		            <path d='M 332.77985,2.38125 372.8168,44.164951 375.06189,2.38125 Z' id='path1942' fill='#4e6472' />
		            <path d='M 119.49876,2.38125 150.92966,38.552292 137.83345,2.38125 Z' id='path1944' fill='#657177' />
		            <path d='M 2.38125,16.475824 50.275951,41.171532 38.302273,2.38125 Z' id='path1946' fill='#6b7377' />
		            <path d='M 372.8168,44.164951 420.33734,43.042419 375.06189,2.38125 Z' id='path1948' fill='#496170' />
		            <path d='M 420.33734,43.042419 474.21887,23.585197 440.54291,2.38125 Z' id='path1950' fill='#415d6d' />
		            <path d='m 474.21887,23.585197 31.39987,38.914441 V 16.475824 Z' id='path1952' fill='#3c5a6c' />
		            <path d='M 76.094185,2.38125 102.66078,66.241417 119.49876,2.38125 Z' id='path1954' fill='#687277' />
		            <path d='M 2.38125,62.499638 50.275951,41.171532 2.38125,16.475824 Z' id='path1956' fill='#666e73' />
		            <path d='M 102.66078,66.241417 150.92966,38.552292 119.49876,2.38125 Z' id='path1958' fill='#5e6b73' />
		            <path d='M 204.06284,64.744705 239.2355,2.38125 195.83093,13.108232 Z' id='path1960' fill='#586972' />
		            <path d='M 50.275951,41.171532 102.66078,66.241417 76.094185,2.38125 Z' id='path1962' fill='#677075' />
		            <path d='M 239.2355,2.38125 293.49121,34.43634 274.034,2.38125 Z' id='path1964' fill='#596a75' />
		            <path d='m 150.92966,38.552292 53.13318,26.192413 -8.23191,-51.636473 z' id='path1966' fill='#556670' />
		            <path d='M 472.72217,79.337624 505.61874,62.499638 474.21887,23.585197 Z' id='path1968' fill='#355668' />
		            <path d='M 293.49121,34.43634 372.8168,44.164951 332.77985,2.38125 Z' id='path1970' fill='#4f6471' />
		            <path d='m 420.33734,43.042419 52.38483,36.295205 1.4967,-55.752427 z' id='path1972' fill='#3a586a' />
		            <path d='M 239.2355,2.38125 271.78894,83.079391 293.49121,34.43634 Z' id='path1974' fill='#4c616e' />
		            <path d='M 204.06284,64.744705 271.78894,83.079391 239.2355,2.38125 Z' id='path1976' fill='#556771' />
		            <path d='m 472.72217,79.337624 32.89657,8.231895 V 62.499638 Z' id='path1978' fill='#2e5164' />
		            <path d='M 159.16156,99.543198 204.06284,64.744705 150.92966,38.552292 Z' id='path1980' fill='#4d606b' />
		            <path d='m 293.49121,34.43634 55.00409,65.85521 24.3215,-56.126599 z' id='path1982' fill='#425b6a' />
		            <path d='m 102.66078,66.241417 56.50078,33.301781 -8.2319,-60.990906 z' id='path1984' fill='#50616c' />
		            <path d='M 2.38125,87.569519 50.275951,41.171532 2.38125,62.499638 Z' id='path1986' fill='#57636c' />
		            <path d='M 271.78894,83.079391 348.4953,100.29155 293.49121,34.43634 Z' id='path1988' fill='#415a69' />
		            <path d='M 372.8168,44.164951 407.24112,116.007 420.33734,43.042419 Z' id='path1990' fill='#3b5869' />
		            <path d='M 112.38939,116.007 159.16156,99.543198 102.66078,66.241417 Z' id='path1992' fill='#455866' />
		            <path d='M 348.4953,100.29155 407.24112,116.007 372.8168,44.164951 Z' id='path1994' fill='#3a5667' />
		            <path d='M 407.24112,116.007 472.72217,79.337624 420.33734,43.042419 Z' id='path1996' fill='#325264' />
		            <path d='m 472.72217,79.337624 32.89657,38.914436 V 87.569519 Z' id='path1998' fill='#284e61' />
		            <path d='m 159.16156,99.543198 51.63647,25.444062 -6.73519,-60.242555 z' id='path2000' fill='#415866' />
		            <path d='M 210.79803,124.98726 271.78894,83.079391 204.06284,64.744705 Z' id='path2002' fill='#3f5766' />
		            <path d='M 50.275951,41.171532 63.746334,135.83839 102.66078,66.241417 Z' id='path2004' fill='#55636c' />
		            <path d='M 2.38125,87.569519 63.746334,135.83839 50.275951,41.171532 Z' id='path2006' fill='#55626b' />
		            <path d='M 63.746334,135.83839 112.38939,116.007 102.66078,66.241417 Z' id='path2008' fill='#485a67' />
		            <path d='M 471.97382,138.45764 505.61874,118.25206 472.72217,79.337624 Z' id='path2010' fill='#264c60' />
		            <path d='m 271.78894,83.079391 42.28204,57.249129 34.42432,-40.03697 z' id='path2012' fill='#314f61' />
		            <path d='m 271.78894,129.10321 42.28204,11.22531 -42.28204,-57.249129 z' id='path2014' fill='#2f4c5d' />
		            <path d='m 210.79803,124.98726 60.99091,4.11595 V 83.079391 Z' id='path2016' fill='#355161' />
		            <path d='M 2.38125,118.25206 63.746334,135.83839 2.38125,87.569519 Z' id='path2018' fill='#445462' />
		            <path d='m 407.24112,116.007 64.7327,22.45064 0.74835,-59.120016 z' id='path2020' fill='#264c5f' />
		            <path d='m 471.97382,138.45764 33.64492,17.21216 v -37.41774 z' id='path2022' fill='#1d475b' />
		            <path d='M 129.22737,158.28903 159.16156,99.543198 112.38939,116.007 Z' id='path2024' fill='#374e5f' />
		            <path d='M 2.38125,155.6698 63.746334,135.83839 2.38125,118.25206 Z' id='path2026' fill='#384a5c' />
		            <path d='m 348.4953,100.29155 42.65622,67.7261 16.0896,-52.01065 z' id='path2028' fill='#274a5d' />
		            <path d='M 63.746334,135.83839 129.22737,158.28903 112.38939,116.007 Z' id='path2030' fill='#344b5d' />
		            <path d='m 314.07098,140.32852 77.08054,27.68913 -42.65622,-67.7261 z' id='path2032' fill='#284a5d' />
		            <path d='M 129.22737,158.28903 210.79803,124.98726 159.16156,99.543198 Z' id='path2034' fill='#344d5e' />
		            <path d='m 2.38125,155.6698 47.894701,32.17924 13.470383,-52.01065 z' id='path2036' fill='#2c4357' />
		            <path d='m 210.79803,124.98726 52.01066,60.61673 8.98025,-56.50078 z' id='path2038' fill='#29485b' />
		            <path d='m 262.80869,185.60399 51.26229,-45.27547 -42.28204,-11.22531 z' id='path2040' fill='#25465a' />
		            <path d='m 391.15152,168.01765 80.8223,-29.56001 -64.7327,-22.45064 z' id='path2042' fill='#22475b' />
		            <path d='m 2.38125,182.98474 47.894701,4.8643 L 2.38125,155.6698 Z' id='path2044' fill='#253c53' />
		            <path d='m 464.86444,197.20348 40.7543,-41.53368 -33.64492,-17.21216 z' id='path2046' fill='#164257' />
		            <path d='m 464.86444,197.20348 40.7543,-14.21874 V 155.6698 Z' id='path2048' fill='#113f54' />
		            <path d='m 129.22737,158.28903 68.84863,38.16609 12.72203,-71.46786 z' id='path2050' fill='#274458' />
		            <path d='m 324.54794,193.83589 66.60358,-25.81824 -77.08054,-27.68913 z' id='path2052' fill='#1c4156' />
		            <path d='m 63.746334,135.83839 37.791916,69.22281 27.68912,-46.77217 z' id='path2054' fill='#274156' />
		            <path d='m 198.076,196.45512 64.73269,-10.85113 -52.01066,-60.61673 z' id='path2056' fill='#244357' />
		            <path d='m 262.80869,185.60399 61.73925,8.2319 -10.47696,-53.50737 z' id='path2058' fill='#1c4055' />
		            <path d='m 391.15152,168.01765 73.71292,29.18583 7.10938,-58.74584 z' id='path2060' fill='#164156' />
		            <path d='M 50.275951,187.84904 101.53825,205.0612 63.746334,135.83839 Z' id='path2062' fill='#243e54' />
		            <path d='m 399.7576,209.55133 65.10684,-12.34785 -73.71292,-29.18583 z' id='path2064' fill='#103c52' />
		            <path d='m 464.86444,197.20348 40.7543,20.20558 v -34.42432 z' id='path2066' fill='#0c3c52' />
		            <path d='M 2.38125,217.40906 50.275951,187.84904 2.38125,182.98474 Z' id='path2068' fill='#1c354e' />
		            <path d='m 324.54794,193.83589 75.20966,15.71544 -8.60608,-41.53368 z' id='path2070' fill='#123c52' />
		            <path d='m 137.83345,235.74374 -8.60608,-77.45471 -27.68912,46.77217 z' id='path2072' fill='#1a3850' />
		            <path d='m 50.275951,187.84904 25.818234,47.8947 25.444065,-30.68254 z' id='path2074' fill='#13304b' />
		            <path d='m 198.076,196.45512 41.1595,39.28862 23.57319,-50.13975 z' id='path2076' fill='#12364e' />
		            <path d='m 399.7576,209.55133 40.78531,26.19241 24.32153,-38.54026 z' id='path2078' fill='#08374e' />
		            <path d='m 2.38125,217.40906 35.921023,18.33468 11.973678,-47.8947 z' id='path2080' fill='#14304b' />
		            <path d='m 119.49876,235.74374 h 18.33469 l -36.2952,-30.68254 z' id='path2082' fill='#0e324b' />
		            <path d='m 324.54794,193.83589 50.51395,41.90785 24.69571,-26.19241 z' id='path2084' fill='#0a364d' />
		            <path d='M 137.83345,235.74374 198.076,196.45512 129.22737,158.28903 Z' id='path2086' fill='#1b3a51' />
		            <path d='m 274.034,235.74374 50.51394,-41.90785 -61.73925,-8.2319 z' id='path2088' fill='#0f374e' />
		            <path d='M 239.2355,235.74374 H 274.034 L 262.80869,185.60399 Z' id='path2090' fill='#0f354d' />
		            <path d='M 38.302273,235.74374 H 76.094185 L 50.275951,187.84904 Z' id='path2092' fill='#14304a' />
		            <path d='m 332.77985,235.74374 h 42.28204 l -50.51395,-41.90785 z' id='path2094' fill='#07334b' />
		            <path d='M 2.38125,235.74374 H 38.302273 L 2.38125,217.40906 Z' id='path2096' fill='#0e324b' />
		            <path d='M 76.094185,235.74374 H 119.49876 L 101.53825,205.0612 Z' id='path2098' fill='#0e324b' />
		            <path d='m 274.034,235.74374 h 58.74585 l -8.23191,-41.90785 z' id='path2100' fill='#09334b' />
		            <path d='m 464.86444,197.20348 40.7543,38.54026 v -18.33468 z' id='path2102' fill='#05374d' />
		            <path d='m 440.54291,235.74374 h 65.07583 l -40.7543,-38.54026 z' id='path2104' fill='#06384e' />
		            <path d='m 375.06189,235.74374 h 65.48102 L 399.7576,209.55133 Z' id='path2106' fill='#05344b' />
		            <path d='M 137.83345,235.74374 H 239.2355 L 198.076,196.45512 Z' id='path2108' fill='#0e324b' />
		        </g>

		        <g id={'g143'} transform={`${flip ? 'scale(1 -1) rotate(.018 753625.485 .032)' : 'none'}`}>
			        <path
			        	id='path3510'
			        	fill={`${flip ? '#1b2b35' : '#2f4c5d'}`}
			        	transform='matrix(.26458 0 0 .26458 2.249 2.249)' 
			        	d='M 951.5 683.74805 A 215.66758 201.52543 0 0 0 735.87891 884.41406 L 1167.1348 884.41406 A 215.66758 201.52543 0 0 0 951.5 683.74805 z' />
		        </g>
		    </g>
		</svg>
	);
};


RockBottom.propTypes = {
	flip: PropTypes.bool,
};

RockBottom.defaultProps = {
	flip: false,	
};


export default RockBottom;
