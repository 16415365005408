/*
	~ Scroll Position Component
*/

import React, { Component, createContext } from 'react';

const ScrollPositionContext = createContext({ windowPosition: 0 });


export class ScrollPositionProvider extends Component {

	state = { windowPosition: 0 };

	ticking = false;

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		if (!this.ticking) requestAnimationFrame(this.publishScrollPosition);

		this.ticking = true;
	};

	publishScrollPosition = () => {
		this.setState({ windowPosition: window.pageYOffset }, () => {
			this.ticking = false;
		});
	};

	render() {
		return (
			<ScrollPositionContext.Provider value={this.state}>
				{ this.props.children }
			</ScrollPositionContext.Provider>
		);
	}
}

export const ScrollPositionConsumer = ScrollPositionContext.Consumer;