/*
	~ Input Field Component
*/

import React from 'react';

import {
	MenuItem,
	TextField,

} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	dropdown: {
		width: '100%',
		marginBottom: 40,
	},
});

export default withStyles(styles)(
	({
		id,
		meta,
		input,
		items,
		label,
		classes,
		helperText,
		labelComponent,
		required = false,
		includeEmptyDefault,
		meta: { error, touched },

	}) => {
		const { 
			value,
			onChange,

		} = input;

		return (
			<TextField
				select
				id={id}
				label={label}
				value={value}
				margin={'normal'}
				onChange={onChange}
				variant={'outlined'}
				className={classes.dropdown}
				helperText={renderFormHelperText(touched, error, helperText)}>
				
				{includeEmptyDefault && (
					<MenuItem value={''}>
						Not Sure
					</MenuItem>
				)}

				{items.map((option, index) => (
					<MenuItem key={`type_${index}`} value={option}>
						{option}
					</MenuItem>
				))}
			</TextField>
		);
	}
);


const renderFormHelperText = (touched, error, helperText) => {
	if(touched && !!error) {
		return error;
	
	} else if(!!helperText && helperText !== '' && helperText !== ' ') {
		return helperText;
	
	} else {
		return null;
	}
};
