/*
	~ REDUX Reducer App Action Key Constants

	FORMAT: const name + '_ACTION', all uppercase
*/

//Application Settings Actions
export const SETTING_NETWORK_STATUS = 'SETTING_NETWORK_STATUS_ACTION';
export const NETWORK_STATUS_ONLINE = 'NETWORK_STATUS_ONLINE_ACTION';
export const NETWORK_STATUS_OFFLINE = 'NETWORK_STATUS_OFFLINE_ACTION';
