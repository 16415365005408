/*
	~ MaterialUITheme View
*/

import React from 'react';

import { MuiThemeProvider } from '@material-ui/core/styles';
import Theme from 'globalStyles/centralMuiThemeStyles';

export default props => (
	<MuiThemeProvider theme={Theme}>
		{ (props.children || null) }
	</MuiThemeProvider>
);