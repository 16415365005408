/*
	~ Notification Center View
*/

import React, { Component } from 'react';

import { Snackbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { 
	MESSAGE_TYPE_ERROR, 
	MESSAGE_TYPE_SUCCESS, 

} from 'reducers/NotificationCenter/actionTypes';

import { 
	Info, 
	Warning,

} from '@material-ui/icons';

import Styles from './styles';
import deepEqual from 'deep-equal';
import classnames from 'classnames';


class NotificationCenterView extends Component {

	//rN Lifecycle ----------------------

	constructor(props) {
		super(props);

		this.state = {
			message: '',
			isOpened: false,
			messageType: MESSAGE_TYPE_SUCCESS,
		};
	}


	componentWillReceiveProps(nextProps) {
		const { messages } = nextProps;

		if(messages.length > 0 && deepEqual(this.props.messages, messages, {strict: true}) === false) {
			const { message, messageType } = messages[0];

			this.setState({ 
				message,
				messageType,
				isOpened: true,
			});
		}
	}

	
	render() {
		const { classes } = this.props;
		const { 
			message,
			isOpened,
			messageType,

		} = this.state;

		return (
			<Snackbar
				key={message}
				open={isOpened}
				autoHideDuration={3000}
				onClose={this.__handleNotificationCenterOnClose}
				anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
				ContentProps={{
					classes: {
						message: classes.boxShape,
						root: classnames({
							[classes.error]: messageType === MESSAGE_TYPE_ERROR,
							[classes.info]: messageType === MESSAGE_TYPE_SUCCESS,
						}),
					}
				}}
				message={
					<React.Fragment>
						{this.renderIcon()}

						<span id='message-id'>
							{ message }
						</span>
					</React.Fragment>
				} />
		);
	}


	renderIcon() {
		const { classes } = this.props;
		const { messageType } = this.state;
		let icon = null;

		switch(messageType) {
			case MESSAGE_TYPE_SUCCESS:
				icon = (<Info classes={{root: classes.messageIcon}} />);
				break;

			case MESSAGE_TYPE_ERROR:
				icon = icon = (<Warning classes={{root: classes.messageIcon}} />);
				break;

			default:
				break;
		}

		return icon;
	}



	//Functionality ---------------------



	//Events ----------------------------

	__handleNotificationCenterOnClose = event => {
		const { userHasSeenNotification } = this.props;

		this.setState({ isOpened: false });
		if(userHasSeenNotification)	userHasSeenNotification();
	};
	
}

export default withStyles(Styles)(NotificationCenterView);

