/*
	~ App Reducer Actions
*/

import { 
	ADD_MESSAGE_TO_NOTIFICATION_CENTER,
	REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER,

} from './actionTypes';

import { MESSAGE_TYPE_SUCCESS } from 'reducers/NotificationCenter/actionTypes';


/* ACTION CREATORS */

export function openNotificationCenterAC({message = '', messageType = MESSAGE_TYPE_SUCCESS}) {
	return { 
		message,
		messageType,
		type: ADD_MESSAGE_TO_NOTIFICATION_CENTER,
	};
}


export function removeViewedNotificationAC() {
	return { type: REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER };
}