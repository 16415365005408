/*
	~ App View
*/

import React, { Component } from 'react';

import { CssBaseline } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ScrollPositionProvider } from 'atoms/_Scroll';

import PropTypes from 'prop-types';
import Theme from 'atoms/_materialUITheme';
import Navigator from 'views/Navigator/navigatorContainer';
import EmailDialog from 'molecules/EmailDialog/emailDialogContainer';
import NotificationCenter from 'molecules/NotificationCenter/notificationCenterContainer';

import Styles from './styles';


class AppView extends Component {

	static propTypes = {
		isDialogOpen: PropTypes.bool,
		initializeApplication: PropTypes.func.isRequired,
	};

	static defaultProps = {
		isDialogOpen: false,
	};


	//rN Lifecycle ----------------------

	componentDidMount() {
		const { initializeApplication } = this.props;

		/*  ---------- BEGIN ----------  */
		initializeApplication();
	}


	render() {
		let { history, classes } = this.props;
		
		return (
			<Theme>
				<CssBaseline />

				<div className={classes.container}>
					<ScrollPositionProvider>
						<Navigator history={history} />

						{ this.renderEmailDialog() }

						<NotificationCenter />
					</ScrollPositionProvider>
				</div>
			</Theme>
		);
	}


	renderEmailDialog() {
		const { isDialogOpen } = this.props;

		return (
			<EmailDialog isOpen={isDialogOpen} title={`Start Your Custom Job`} />
		);
	}
}

export default withStyles(Styles)(AppView);

