/*
	~ REDUX Reducer User Action Key Constants

	FORMAT: const name + '_ACTION', all uppercase
*/

//User Actions Keys
export const SEND_EMAIL = 'SEND_EMAIL_ACTION';
export const OPEN_EMAIL_DIALOG = 'OPEN_EMAIL_DIALOG_ACTION';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS_ACTION';
export const CLOSE_EMAIL_DIALOG = 'CLOSE_EMAIL_DIALOG_ACTION';