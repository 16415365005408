/*
	~ Global style helper functions
*/


import { browserName, getCleanedBrowserVersion } from 'utils/browserUtilities';


export function getLinearGradient(angle, colorsArray = []) {
	let background = colorsArray[0], 
		browerName = browserName,
		version = getCleanedBrowserVersion();

	if(Array.isArray(colorsArray) && colorsArray.length > 0) {
		if(browerName === 'edge' || browerName === 'ie' || browerName === 'opera' || 
				(browerName === 'firefox' && version > 15) ||
					(browerName === 'chrome' && version > 25) ||
						(browerName === 'safari' && version > 6)) {

			background = `linear-gradient(${angle}, ${colorsArray.join(', ')})`;
		
		} else {
			if(browerName === 'firefox') {
				background = `-moz-linear-gradient(${angle}, ${colorsArray.join(', ')})`;

			} else {
				background = `-webkit-linear-gradient(${angle}, ${colorsArray.join(', ')})`;
			}
		}
	}

	return background;
}


//Takes hex value as color
export function getRGBAColor(color, percentage) {
	let rgbObj = hexToRgb(color);
	return `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${percentage})`;
}


export function hexToRgb(hex) {
    let shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => { return r + r + g + g + b + b; });
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}