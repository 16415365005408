/*
	~ Bubble Connector
*/

import { connect } from 'react-redux';

import Bubble from './index.js';


const mapStateToProps = state => {
	return {

	}
};

const mapDispatchToProps = dispatch => {
	return {
		
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(Bubble);

