/*
	~ App Connector
*/

import { connect } from 'react-redux';
import { initializeApplication } from 'reducers/App/actions';

import AppView from './index.js';


const mapStateToProps = ({ emailDialog }) => {
	return {
		isDialogOpen: emailDialog.isOpen,
	};
};

const mapDispatchToProps = {
	initializeApplication,
};


export default connect(mapStateToProps, mapDispatchToProps)(AppView);
