/*
	~ Page Not Found Connector
*/

import PageNotFoundView from './index.js';

import { connect } from 'react-redux';
import { navigateTo } from 'reducers/App/actions';


const mapStateToProps = state => {
	return {};
};

const mapDispatchToProps = {
	navigateTo,
};


export default connect(mapStateToProps, mapDispatchToProps)(PageNotFoundView);

