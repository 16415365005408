/*
	~ Nav Link Styles
*/

export default theme => ({
	link: {
		borderWidth: 0,
		paddingBottom: 2,
		borderBottomWidth: 0,
		borderStyle: 'solid',
		borderColor: theme.palette.secondary.light,
		transition: `border-bottom-width ${theme.transitions.duration.short}ms linear`,
	},
	activeLink: {
		borderBottomWidth: 2,
	},
});

