/*
	~ Notification Center Connector
*/

import { connect } from 'react-redux';

import NotificationCenterView from './index.js';

import { removeViewedNotificationAC } from 'reducers/NotificationCenter/actions';


const mapStateToProps = state => {
	return {
		messages: state.notificationCenter.messages,
	}
};

const mapDispatchToProps = dispatch => {
	return {
		userHasSeenNotification: () => {
			dispatch( removeViewedNotificationAC() );
		},
	}
};


export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenterView);

