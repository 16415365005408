/*
	~ Techno Bubble Component
*/

import React, { Component } from 'react';


import PropTypes from 'prop-types';
import includeClasses from 'classnames';

// import {  } from '@material-ui/core';
// import {  } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

const DEFAULT_SIZE = 450;


class TechnoBubble extends Component {
	
	// Component Definitions -------------

	static propTypes = {
		width: PropTypes.number,
		height: PropTypes.number,
		subtitle: PropTypes.string,
		titleClass: PropTypes.string,
		subtitleClass: PropTypes.string,
		title: PropTypes.string.isRequired,
		children: PropTypes.node.isRequired,
		classes: PropTypes.object.isRequired,
	};

	static defaultProps = {
		width: null,
		height: null,
		titleClass: null,
		subtitleClass: null,
	};


	// rN Lifecycle ----------------------

	constructor(props) {
		super(props);

		this.state = {
			animate: false,
		};
	}

	
	render() {
		const { 
			width,
			title,
			height,
			classes,
			children,
			subtitle,
			titleClass,
			subtitleClass,

		} = this.props;

		const { animate } = this.state;

		return (
			<div 
				className={classes.container}
				onMouseEnter={this.__handleMouseEnter}
				onMouseLeave={this.__handleMouseLeave}
				style={{
					width: width ? width : DEFAULT_SIZE,
					height: height ? height : DEFAULT_SIZE,
				}}>

				<div 
					className={
						includeClasses({
							[classes.spinContainer]: true,
							[classes.spinAnimation]: animate,
						})
					}>

					<div 
						className={
							includeClasses({
								[classes.spinInnerFirst]: true,
							})
						}>

						<div 
							className={
								includeClasses({
									[classes.spinInnerSecond]: true,
									[classes.spinInnerReverseAnimation]: animate,
								})} />
					</div>
				</div>

				<div className={classes.staticContent}>
					<h2 
						className={
							includeClasses({
								[classes.title]: true,
								[classes.hide]: animate,
								[titleClass]: !!titleClass,
							})
						}>
						{ title }
					</h2>
					
					<h3 
						className={
							includeClasses({
								[classes.blurb]: true,
								[classes.hide]: animate,
								[subtitleClass]: !!subtitleClass,
							})
						}>

						{ subtitle }
					</h3>

					<div 
						className={
							includeClasses({
								[classes.hiddenBody]: true,
								[classes.show]: animate,
							})
						}>

						{ children }
					</div>
				</div>
			</div>
		);
	}



	// Functionality ---------------------



	// Events ----------------------------

	__handleMouseEnter = event => {
		this.setState({ animate: true });
	};


	__handleMouseLeave = event => {
		this.setState({ animate: false });
	};
	
}


const styles = theme => ({
	container: {
		zIndex: 1500,
		display: 'flex',
		cursor: 'pointer',
		position: 'relative',
	},

	spinContainer: {
		width: '100%',
		height: '100%',
		borderRadius: '50%',
		position: 'absolute',
		transform: 'rotate(0deg)',
		borderLeftColor: '#425e6f',
		borderBottomColor: '#425e6f',
		border: '5px dashed transparent',
		transition: 'transform 750ms linear',

		'&:after': {
			top: -20,
			left: -20,
			right: -20,
			bottom: -20,
			content: '""',
			borderRadius: '50%',
			position: 'absolute',
			borderRightColor: '#fff',
			border: '25px ridge transparent',
		},
	},
	spinInnerFirst: {
		top: 5,
		left: 5,
		right: 5,
		bottom: 5,
		borderRadius: '50%',
		position: 'absolute',
		transform: 'rotate(0deg)',
		borderTopColor: '#687277',
		borderRightColor: '#687277',
		border: '25px double transparent',
	},
	spinInnerSecond: {
		top: -10,
		left: -10,
		right: -10,
		bottom: -10,
		borderRadius: '50%',
		position: 'absolute',
		transform: 'rotate(0deg)',
		border: '25px dashed transparent',
		transition: 'transform 750ms ease-in-out',
		borderLeftColor: theme.palette.secondary.main,
		borderRightColor: theme.palette.secondary.main,
	},
	
	spinAnimation: {
		transform: 'rotate(180deg)',
	},
	spinInnerReverseAnimation: {
		transform: 'rotate(-360deg)',
	},

	staticContent: {
		width: '100%',
		height: '100%',
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	},

	title: {
		opacity: 1,
		fontSize: '3em',
		marginBottom: 10,
		fontFamily: 'vermin',
		borderBottom: '1px #fff solid',
		transition: `opacity 750ms ease-out`,
		color: theme.palette.primary.contrastText,
	},
	blurb: {
		opacity: 1,
		width: '100%',
		marginTop: 15,
		fontSize: '1.25em',
		textAlign: 'center',
		fontFamily: "Open Sans",
		transition: `opacity 750ms ease-out`,
		color: theme.palette.primary.contrastText,
	},
	hiddenBody: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		opacity: 0,
		padding: '25%',
		display: 'flex',
		fontSize: '1em',
		position: 'absolute',
		alignItems: 'center',
		fontFamily: "Open Sans",
		justifyContent: 'center',
		transition: `opacity 600ms ease-in-out`,
		color: theme.palette.primary.contrastText,
	},

	show: {
		opacity: 1,
	},
	hide: {
		opacity: 0,	
	},
});


export default withStyles(styles)(TechnoBubble);

