/*
	~ All public exposed helpers for browser control, versioning and detection
*/

import { detect } from 'detect-browser';

const browser = detect();


export const browserName = browser.name;


export function getCleanedBrowserVersion() {
	let version = browser.version;

	if(version.includes('.')) {
		version = version.split('.')[0];
	
	} else if(version.includes('-')) {
		version = version.split('-')[0];
	
	} else if(version.includes('/')) {
		version = version.split('/')[0];
	
	} else if(version.includes(' ')) {
		version = version.split(' ')[0];
	}

	return Number(version);
}


export const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};


export const isMaterialWidthInMobile = width => {
    switch(width) {
        case 'xs':
            return true;
        
        case 'sm':
            return true;
        
        case 'md':
            return false;
        
        case 'lg':
            return false;
        
        case 'xl':
            return false;

        default:
            return false;
    }
}

