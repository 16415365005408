/*
	~ Submit Button View
*/

import React from 'react';
import FlatButton from 'atoms/_FlatButton';

import { CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const Styles = theme => ({
	spinner: {
		color: theme.palette.primary.main,
	},
});


export default withStyles(Styles)(
	({
		onClick,
		classes,
		children,
		spinnerSize = 30,
		disabled = false,
		isLoading = false,

	}) => (
		<FlatButton type={'submit'} onClick={onClick}>
			{ isLoading ? __renderSpinner(spinnerSize, classes.spinner) : children }
		</FlatButton>
	)
);


function __renderSpinner(size, spinnerClass) {
	return (
		<CircularProgress
			size={size}
			classes={{ circle: spinnerClass }} />
	);
}

