/*
	~ Email Dialog Connector
*/

import EmailDialogView from './index.js';

import { connect } from 'react-redux';
import { DIALOG_FORMS } from 'constants/formNameKeys';
import { getEmailSubjectByType } from 'constants/dialog';
import { openNotificationCenterAC } from 'reducers/NotificationCenter/actions';
import { MESSAGE_TYPE_SUCCESS, MESSAGE_TYPE_ERROR } from 'reducers/NotificationCenter/actionTypes';

import {
	sendEmail,
	closeEmailDialog,

} from 'reducers/CustomerEmail/actions';

import {
	reset,
	submit,
	reduxForm,

} from 'redux-form';


const mapStateToProps = ({ form, emailDialog }) => {
	return {
		initialType: emailDialog.initialType,
		emailType: (form && form.emailDialogForm) ? form.emailDialogForm.values.emailType : null,
	}
};

const mapDispatchToProps = {
	closeEmailDialog,
	sendForm: submit,
};


export default connect(mapStateToProps, mapDispatchToProps)(
	reduxForm({
		form: DIALOG_FORMS.email,
		onSubmit: (values, dispatch, props) => {
			console.log('props: ', props);
			console.log('values: ', values);
			console.log('dispatch: ', dispatch);

			const { closeEmailDialog } = props;

			closeEmailDialog();

			dispatch(
				sendEmail(getEmailSubjectByType(), values.mainContentInput)
			);

			// dispatch(
				// openNotificationCenterAC({ message: 'Email sent, we will get back to you within 24 hours', messageType: MESSAGE_TYPE_SUCCESS })
			// );

			dispatch( reset(DIALOG_FORMS.email) );
		},

	})(EmailDialogView)
);

