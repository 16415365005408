/*
	~ Customer Email Reducer Actions
*/

import { API_ERROR } from 'reducers/Api/actionTypes';
import { buildFetchAction } from 'reducers/Api/actions';

import { 
	SEND_EMAIL,
	OPEN_EMAIL_DIALOG,
	CLOSE_EMAIL_DIALOG,
	SEND_EMAIL_SUCCESS,

} from './actionTypes';

import { 
	METHODS,
	EMAIL_URL,
	ACCEPT_JSON,
	APPLICATION_JSON,

} from 'constants/api';



/* STATE MANIPULATION */

export const openEmailDialog = (initialType = null) => ({
	initialType,
	type: OPEN_EMAIL_DIALOG,
});


export const closeEmailDialog = dispatch => ({
	type: CLOSE_EMAIL_DIALOG	
});


export const sendEmail = (subject, message) => dispatch => 
	dispatch(
		buildFetchAction(
			METHODS._POST,
			EMAIL_URL,
			{
				body: JSON.stringify({
					payload: { 
						subject,
						message, 
					},
				}),
				headers: {
					[ACCEPT_JSON.key]: ACCEPT_JSON.value,
					[APPLICATION_JSON.key]: APPLICATION_JSON.value,
				},
				types: [
					SEND_EMAIL,
					SEND_EMAIL_SUCCESS,
					API_ERROR,
				],
			},
		)
	);
