/*
	~ Customer Email State Reducer
*/

import {
	SEND_EMAIL,
	OPEN_EMAIL_DIALOG,
	CLOSE_EMAIL_DIALOG,
	SEND_EMAIL_SUCCESS,

} from './actionTypes';

import { CUSTOM } from 'constants/dialog';
import { API_ERROR } from 'reducers/Api/actionTypes';

const initialState = {
	isOpen: false,
	isSendingEmail: false,
	initialType: CUSTOM.value,
}


/* STATE REDUCER */

export default function reducer(state = initialState, action = {}) {

	switch(action.type) {
		case OPEN_EMAIL_DIALOG:
			return {
				...state,
				isOpen: true,
				initialType: (action.initialType || initialState.initialType),
			};

		case CLOSE_EMAIL_DIALOG:
			return {
				...state,
				isOpen: false,
			};

		case SEND_EMAIL:
			return {
				...state,
				isSendingEmail: true,
			};

		case SEND_EMAIL_SUCCESS:
			console.log('action', action);

			return {
				...state,
				isSendingEmail: false,
			};
		
		case API_ERROR:
			console.log('action', action);

			return {
				...state,
				isSendingEmail: false,
			};

		default:
			return state;
	}

}

