/*
	~ Bubble Component
*/

import React, { Component } from 'react';

// import {  } from '@material-ui/core';
// import { TouchApp } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';

import Styles from './styles';
import PropTypes from 'prop-types';
import includeClasses from 'classnames';

const DEFAULT_SHADOW = 10;
const DEFAULT_POSITION = 'relative';
const DEFAULT_DIRECTIONS = ['top', 'left', 'right', 'bottom'];

export const DEFAULT_SIZES = ['xSmall', 'small', 'medium', 'large', 'xLarge'];


class Bubble extends Component {
	
	// Component Definitions -------------

	static propTypes = {
		size: PropTypes.string,
		shadow: PropTypes.number,
		className: PropTypes.string,
		placements: PropTypes.object,
		hiddenContent: PropTypes.string,
	};

	static defaultProps = {
		size: null,
		className: null,
		placements: null,
		hiddenContent: null,
		shadow: DEFAULT_SHADOW,
	};


	// rN Lifecycle ----------------------

	constructor(props) {
		super(props);

		this.state = {
			isHovering: false,
			initialLoopHasRun: false,
		};
	}

	
	render() {
		const {
			size,
			theme,
			shadow,
			classes,
			children,
			className,
			placements,
			hiddenContent,

		} = this.props;

		const { 
			isHovering,
			initialLoopHasRun,

		} = this.state;

		const currentSize = DEFAULT_SIZES.includes(size) ? theme.sizes.circles[size] : theme.sizes.circles['xLarge'];

		return (
			<button 
				type={'button'}
				onMouseEnter={this.__handleButtonMouseEnter}
				onMouseLeave={this.__handleButtonMouseLeave}
				className={
					includeClasses({
						[classes.container]: true,
						[className]: !!className,
					})
				}
				style={{
					width: `${currentSize}px`,
					height: `${currentSize}px`,
					borderRadius: `${(currentSize / 2)}px`,
					boxShadow: this.getShadow(theme.shadows, shadow),
					...this.getPositionPlacements(placements),
				}}>

				<div className={classes.childContainer}>
					{ children }
				</div>

				<div 
					className={
						includeClasses({
							[classes.fill]: true,
							[classes[`fill-${size}`]]: isHovering,
							[classes[`empty-${size}`]]: !isHovering,
						})
					}>

					<div className={classes.hiddenContent}>
						<h3 className={classes.hiddenContentText}>
							{ hiddenContent }
						</h3>
					</div>

					<svg
						x={'0px'}
						y={'0px'}
						version={'1.1'}
						xmlSpace={'preserve'}
						viewBox={`0 0 150 150`}
						xmlns={'http://www.w3.org/2000/svg'}
						enableBackground={`new 0 0 150 150`}
						xmlnsXlink={'http://www.w3.org/1999/xlink'}>

						<path 
							id={'waveShape'}
							fill={theme.palette.secondary.main}
							className={
								includeClasses({
									[classes.waveAnimation]: isHovering,
								})
							}
							style={{
								height: `${currentSize}px`,
								width: `${(currentSize * 2)}px`,
							}}
							d={`M300,300V2.5c0,0-0.6-0.1-1.1-0.1c0,0-25.5-2.3-40.5-2.4c-15,0-40.6,2.4-40.6,2.4
								c-12.3,1.1-30.3,1.8-31.9,1.9c-2-0.1-19.7-0.8-32-1.9c0,0-25.8-2.3-40.8-2.4c-15,0-40.8,2.4-40.8,2.4c-12.3,1.1-30.4,1.8-32,1.9
								c-2-0.1-20-0.8-32.2-1.9c0,0-3.1-0.3-8.1-0.7V300H300z`}
							/>
					</svg>
				</div>
			</button>
		);
	}



	// Functionality ---------------------

	getShadow(shadows, shadow) {
		let currentShadow = shadows[DEFAULT_SHADOW];

		if (shadow > 25) {
			switch(shadow) {
				case 26:
					currentShadow = `rgba(0, 0, 0, 0.2) 0px 20px 6px -3px, rgba(0, 0, 0, 0.14) 0px 25px 10px 4px, rgba(0, 0, 0, 0.12) 0px 4px 12px 18px`;
					break;

				default:
					break;
			}
		
		} else {
			currentShadow = shadows[shadow];
		}

		return currentShadow;
	};


	getPositionPlacements(placements) {
		let positions = {};

		if (placements && Object.keys(placements).length > 0) {
			positions['position'] = (placements['position'] || DEFAULT_POSITION);

			if (placements['directions'] && Object.keys(placements.directions).length > 0) {
				Object.keys(placements.directions).forEach(directionKey => {
					if (DEFAULT_DIRECTIONS.includes(directionKey)) positions[directionKey] = placements.directions[directionKey];
				});
			}
		}

		return positions;
	};



	// Events ----------------------------

	__handleButtonMouseEnter = event => {
		this.setState({ initialLoopHasRun: true, isHovering: true });
	};


	__handleButtonMouseLeave = event => {
		this.setState({ isHovering: false });
	};
	
}


export default withStyles(Styles, { withTheme: true })(Bubble);

