/*
	~ Flat Button View
*/

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import includeClasses from 'classnames';

import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
	button: {
		opacity: 1,
		marginTop: 10,
		fontWeight: 600,
		outline: 'none',
		marginBottom: 10,
		letterSpacing: 3,
		cursor: 'pointer',
		fontSize: '1.25em',
		padding: '0 50px',
		textTransform: 'uppercase',
		backgroundColor: 'transparent',
		fontFamily: theme.typography.fontFamily,
		height: theme.sizes.buttons.defaultHeight,
		color: `${theme.palette.primary.contrastText}`,
		transitionProperty: ['background-color', 'border', 'color'],
		border: `${theme.sizes.borderSize}px solid ${theme.palette.primary.main}`,
		transition: `${theme.transitions.duration.shorter}ms ${theme.transitions.easing.easeIn}`,
	},

	isHovering: {
		color: `${theme.palette.primary.light}`,
		backgroundColor: `${theme.palette.primary.main}`,
		border: `${theme.sizes.borderSize}px solid ${theme.palette.primary.light}`,
	},
	isPressing: {
		opacity: 0.65,
	},
});


class FlatButton extends Component {

	static propTypes = {
		type: PropTypes.string,
		onClick: PropTypes.func,
	};

	static defaultProps = {
		type: 'button',
	};


	//rN Lifecycle ----------------------

	constructor(props) {
		super(props);

		this.state = {
			isHovering: false,
			isPressing: false,
		};
	}

	
	render() {
		const { 
			type,
			classes,
			children,
			className,

		} = this.props;

		const {
			isHovering,
			isPressing,

		} = this.state;

		return (
			<button 
				type={type}
				onMouseUp={this.__handleButtonMouseUp}
				onMouseDown={this.__handleButtonMouseDown}
				onMouseEnter={this.__handleButtonMouseEnter}
				onMouseLeave={this.__handleButtonMouseLeave}
				className={
					includeClasses({
						[classes.button]: true,
						[classes.isHovering]: isHovering,
						[classes.isPressing]: isPressing,
						[className]: !!className,
					})
				}>

				{ children }
			</button>
		);
	}



	//Functionality ---------------------

	hover() { this.setState({ isHovering: true }); }


	unhover() { this.setState({ isHovering: false }); }


	press() { this.setState({ isPressing: true }); }


	unpress() { this.setState({ isPressing: false }); }



	//Events ----------------------------

	__handleButtonMouseEnter = event => {
		event.preventDefault();
		event.stopPropagation();

		this.hover();
	};


	__handleButtonMouseLeave = event => {
		event.preventDefault();
		event.stopPropagation();

		this.unhover();
		this.unpress();
	};

	__handleButtonMouseDown = event => {
		event.preventDefault();
		event.stopPropagation();

		this.press();
	};


	__handleButtonMouseUp = event => {
		const { onClick } = this.props;
		event.preventDefault();
		event.stopPropagation();

		this.unpress();
		if (onClick) onClick(event);
	};
	
}


export default withStyles(styles)(FlatButton);

