/*
	~ Navigator View
*/

import React, { Component } from 'react';

import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';

//Main Application Routing File
import { ROUTES, ROUTE_OBJS } from 'settings/routes';
//==


export default class NavigatorView extends Component {

	//rN Lifecycle ----------------------

	render() {
		let { history } = this.props;

		return (
			<ConnectedRouter history={history}>
				{ this.renderRoutes() }
			</ConnectedRouter>
		);
	}


	renderRoutes() {
		return (
			<Switch>
				{ this.renderAllRoutes() }
			</Switch>
		);
	}


	renderAllRoutes() {
		const routeKeys = Object.keys(ROUTE_OBJS); 
		let availableRoutes = [];

		if(!!ROUTE_OBJS && routeKeys.length > 0) {
			routeKeys.forEach((routeKey, routeIndex) => {
				availableRoutes.push(
					<Route
						exact
						path={ROUTE_OBJS[routeKey].path}
						key={`${routeKey}_${routeIndex}`}
						render={this.renderRoute(routeKey)} />
				);
			});
		}

		return availableRoutes;
	}


	renderRoute(routeKey = ROUTES.PAGE_NOT_FOUND) {
		return props => {
			if(Object.keys(ROUTES).length > 0) {
				const routeObj = ROUTE_OBJS[routeKey];
				const TemporaryComponent = routeObj.component;

				return (
					<TemporaryComponent
						routePath={routeObj.path}
						routeProps={{ title: routeObj.title, ...routeObj.routeProps }}
						{ ...props } /> 
				);

			} else {
				console.log('You have no routes in this application, please setup initial routing');
				return null;
			}
		};
	}
}

