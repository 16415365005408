/*
	~ All Static API Url's pathing for application
*/


// URLs ----------------------------------------------------

import SERVER from 'constants/server';

export const EMAIL_URL = `${SERVER.DEV.API}/email/v1/`;
// export const _URL = `${SERVER.DEV.API}/[_ROUTE_]/[__VERSION__]/`;



// Methods -------------------------------------------------

export const METHODS = {
	_GET: 'GET',
	_PUT: 'PUT',
	_POST: 'POST',
	_PATCH: 'PATCH',
	_DELETE: 'DELETE',
};



// Headers -------------------------------------------------

export const ACCEPT_JSON = {
	key: 'Accept',
	value: 'application/json',
};

export const APPLICATION_JSON = {
	key: 'Content-Type',
	value: 'application/json',
};

export const X_WWW_FORM_ENCODED = {
	key: 'Content-Type',
	value: 'application/x-www-form-urlencoded',
};

export const AUTHORIZATION = {
	key: 'Authorization',
	value: 'Bearer',
};

