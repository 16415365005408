/*
	~ App Settings State Reducer & Actions
*/

import { 
	NETWORK_STATUS_ONLINE,
	NETWORK_STATUS_OFFLINE,

} from './actionTypes';

// eslint-disable-next-line
const UI_BUILD = __UI_BUILD__;

// eslint-disable-next-line
const UI_VERSION = __UI_VERSION__;

const initialState = {
	isOnline: false,
	ui: {
		build: UI_BUILD, 
		version: UI_VERSION,
	},
}


/* STATE REDUCER */

export default function reducer(state = initialState, action = {}) {
	switch(action.type) {
		case NETWORK_STATUS_ONLINE:
			return {
				...state,
				isOnline: true,
			};

		case NETWORK_STATUS_OFFLINE:
			return {
				...state,
				isOnline: false,
			};

		default: 
			return state;
	}

}

