/*
	~ All Form Field Rendering By Functions
*/

import React from 'react';
import InputField from 'atoms/_FormFields/_inputField';
import DropdownField from 'atoms/_FormFields/_dropdownField';

import { Field } from 'redux-form';


// Field Rendering ---------------------------------

export function renderInputField({
	fieldId,
	fieldName,
	validation,
	parse = null,
	type = 'text',
	inputProps = {},
	inputLabel = '',
	required = false,
	autoFocus = false,
	helperText = false,
	isMultiline = false,
	labelComponent = null,
	formControlClasses = null,

}) {
	return (
		<Field
			id={fieldId}
			parse={parse}
			name={fieldName}
			required={required}
			validate={validation}
			component={InputField}
			labelComponent={labelComponent}
			props={{
				type,
				autoFocus,
				inputLabel,
				helperText,
				inputProps,
				isMultiline,
				formControlClasses,
			}} />
	);
}


export function renderDropdownField({
	fieldId,
	fieldName,
	validation,
	helperText,
	label = '',
	items = [],
	parse = null,
	required = false,
	labelComponent = null,
	includeEmptyDefault = true,

}) {
	return (
		<Field
			id={fieldId}
			parse={parse}
			name={fieldName}
			required={required}
			validate={validation}
			component={DropdownField}
			labelComponent={labelComponent}
			props={{
				label,
				items,
				helperText,
				includeEmptyDefault,
			}} />
	);
}
