/*
	==================================================================
	~ MAIN CENTRAL APPLICATION ENTRY POINT ---------------------------
	==================================================================
*/

// React
import React from 'react';
import ReactDOM from 'react-dom';
// --

// Services
import AppStore from './storeProvider';
import * as serviceWorker from 'services/serviceWorker';
// --

// Inject !sole! application CSS setup
// eslint-disable-next-line
import FoundationalStyles from 'assets/styles/foundationalStyles.css';
// --

// ==================================================================

// ==================================================================
// GO!! -------------------------------------------------------------
// ==================================================================
ReactDOM.render(<AppStore />, document.getElementById('root'));
serviceWorker.unregister();
// ==================================================================