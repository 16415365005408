/*
	~ Notification Center styles
*/


export default theme => ({
	error: {
		paddingLeft: 0,
		paddingRight: 0,
		color: theme.palette.error.contrastText,
		backgroundColor: theme.palette.error.main,
	},
	boxShape: {
		fontSize: 18,
		display: 'flex',
		fontWeight: 'bold',
		padding: '8px 20px',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	info: {
		paddingLeft: 0,
		paddingRight: 0,
		color: theme.palette.secondary.main,
		backgroundColor: theme.palette.secondary.contrastText,
	},
	messageIcon: {
		marginRight: 15,
	},
});

