/*
	~ Global Navbar Styles
*/

import { getRGBAColor } from 'helpers/styleHelpers';


export default theme => ({
	/*
		!! FOR DEVELOPMENT ONLY !!
		~~REMOVE IN PRODUCTION~~
	*/
	developmentBox: {
		top: 0,
		right: 0,
		fontSize: 10,
		fontStyle: 'italic',
		position: 'absolute',
	},
	/*
		//---
	*/

	appBar: {
	    width: '100%',
		zIndex: 99999,
		top: 0, left: 0,
		display: 'flex',
		position: 'fixed',
		alignItems: 'center',
		flexDirection: 'row',
		boxShadow: theme.shadows[0],
		backgroundColor: 'transparent',
		height: theme.sizes.defaultNavbarHeight,
		transition: `background-color 900ms ${theme.transitions.easing.easeIn}`,

		'&.active': {
			boxShadow: theme.shadows[5],
			backgroundColor: theme.palette.primary.main,
		},
	},
	activeToolbar: {
		backgroundColor: theme.palette.primary.main,
	},
	activeToolbarVersion: {
		color: theme.palette.primary.contrastText,
	},

	titleBar: {
		width: '100%',
		position: 'relative',
	},

	toolbarContent: {
		flex: 1,
		height: '100%',
		display: 'flex',
		paddingTop: 7.5,
		paddingBottom: 15,
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},

	toolbarDetails: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
	},
	siteLogo: {
		height: (theme.sizes.defaultNavbarHeight / 1.5),
	},

	menu: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	menuItem: {
		padding: 10,
		textDecoration: 'none',
		color: theme.palette.primary.contrastText,

		'&:hover': {
			fontWeight: 'bold',
			fontStyle: 'italic',
			color: theme.palette.secondary.light,
			backgroundColor: getRGBAColor(theme.palette.primary.main, 0.25),
		},

		'&:not(:last-child)': {
			marginRight: 20,
		},
	},

	contactUsButton: {
		height: 'auto',
		fontSize: '0.9em',
		padding: '8px 20px',
	},
});

