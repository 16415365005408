/*
	~ Configure the Redux Store and Setup Persistence
*/

import { persistStore } from 'redux-persist'
import { apiMiddleware } from 'redux-api-middleware';
import { routerMiddleware } from 'react-router-redux';

import { 
	compose, 
	createStore, 
	applyMiddleware, 
} from 'redux';

import thunk from 'redux-thunk';
import setup from 'utils/storageSetup';
import combinedReducers from 'reducers';


export default function(history) {
	setup(); // <-- Storage initialization

	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const store = createStore(
		combinedReducers, 
		undefined,
		composeEnhancers(
			applyMiddleware( thunk, routerMiddleware(history), apiMiddleware )
		)
	);

	let persistor = persistStore(store);

	return { store, persistor };
};

