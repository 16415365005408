/*
	~ Global Styles ansd Constants
*/

import {
	blue,
	blueGrey,

} from '@material-ui/core/colors';


// Private to module
const __DEFAULT_GREY = blueGrey[900];
const __DEFAULT_BLACK = '#000a12';
const __DEFAULT_LIGHT_GREY = '#4f5b62';
const __DEFAULT_SILVER = '#d2d2d2';

const __DEFAULT_WHITE = '#ffffff';
const __DEFAULT_OFF_WHITE = '#FCFCFC';

const __DEFAULT_BLUE = blue[800];
const __DEFAULT_DARK_BLUE = '#0e324b';
const __DEFAULT_LIGHT_BLUE = '#5e92f3';

const __DEFAULT_RED = '#EF5557';
// ==


// Global Settings -------------------------------------------------

export const BUTTON_ANIMATION_DURATION = 300;
export const GALLERY_MENU_Z_INDEX = 100;



// Global Shared Styles --------------------------------------------

// Fonts
export const GLOBAL_FONT = `"Open Sans"`;



// Global Sizing ---------------------------------------------------

// Heights
export const DEFAULT_GLOBAL_NAVBAR_HEIGHT = 100;

// Shapes & Layout
export const DEFAULT_SKEW_ANGLE = 45;
export const DEFAULT_BORDER_SIZE = 2;
export const CIRCULAR_DIAMETER = 500;
export const BIG_SITE_LOGO_SQUARE = '24vh';
export const DEFAULT_SLANT_ANGLE = '1.75deg';

// Buttons
export const DEFAULT_BUTTON_HEIGHT = 75;

// Application
export const FULL_SCREEN_WIDTH = '100vw';
export const FULL_SCREEN_HEIGHT = '100vh';



// Global Colours --------------------------------------------------

export const DEFAULT_INPUT_COLOR = __DEFAULT_OFF_WHITE;
export const DEFAULT_INPUT_TEXT_COLOR = __DEFAULT_BLACK;
export const DEFAULT_BACKGROUND_COLOR = __DEFAULT_WHITE;
export const DEFAULT_EMPTY_SPACE_COLOR = __DEFAULT_LIGHT_GREY;

export const PRIMARY_COLORS = {
	main: __DEFAULT_GREY,
	light: __DEFAULT_SILVER,
	dark: __DEFAULT_BLACK,
	contrastText: __DEFAULT_WHITE,
};

export const SECONDARY_COLORS = {
	main: __DEFAULT_BLUE,
	light: __DEFAULT_LIGHT_BLUE,
	dark: __DEFAULT_DARK_BLUE,
	contrastText: __DEFAULT_BLACK,
};

export const NOTIFICATION_COLORS = {
	main: __DEFAULT_RED,
	dark: __DEFAULT_GREY,
	light: __DEFAULT_OFF_WHITE,
	contrastText: __DEFAULT_WHITE,
};

export const ACTION_COLORS = {
	disabled: __DEFAULT_LIGHT_GREY,
	disabledBackground: __DEFAULT_GREY,
};

// Content Containers
export const CENTRALIZED_BOX = {
	width: '40%',
	minWidth: 350,
	display: 'flex',
	minHeight: '50vh',
	alignItems: 'center',
	flexDirection: 'column',
};

