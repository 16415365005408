/*
	~ All Browser localStorage Session Keys
*/

import storage from 'redux-persist/lib/storage';

export const UI_DETAILS = 'uiDetails_key';


export const appPersistConfig = {
  storage,
  key: 'appDetails_key',
  whitelist: ['details'],
};
