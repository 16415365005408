/*
	~ Service Bubble Component
*/

import React, { Component } from 'react';

import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getLinearGradient } from 'helpers/styleHelpers';

import PropTypes from 'prop-types';
import includeClasses from 'classnames';

export const LEFT_POSITION = 'left_position_key';
export const RIGHT_POSITION = 'right_position_key';
const ICON_SIZE = 100;


class ServiceBubble extends Component {
	
	// Component Definitions -------------

	static propTypes = {
		showing: PropTypes.bool,
		position: PropTypes.string,
		tooltipContent: PropTypes.string,
		// IconComponent: PropTypes.func.isRequired,
		// bodyTextComponent: PropTypes.node.isRequired,
	};

	static defaultProps = {
		showing: false,
		tooltipContent: '',
		position: LEFT_POSITION,
	};


	// rN Lifecycle ----------------------

	constructor(props) {
		super(props);

		this.state = {};
	}


	render() {
		const {
			showing,
			classes,
			position,
			IconComponent,
			tooltipContent,
			bodyTextComponent,

		} = this.props;

		return (
			<Tooltip
				placement={`top`}
				title={tooltipContent}
				id={`service_bubble_tip_${new Date().toISOString()}`}>

				<div 
					className={
						includeClasses({
							[classes.container]: true,
							[classes[ this.getPosition(position) ]]: true,
							[classes.showing]: showing,
						})
					}>

					<div className={classes.contentCenter}>
						<div 
							className={
								includeClasses({
									[classes.iconContainer]: true,
									[classes[ `iconColor-${this.getPosition(position)}` ]]: true,
								})
							}>

							<IconComponent classes={{ root: classes.icon }} />
						</div>
						
						<h3 className={classes.title}>
							{ bodyTextComponent }
						</h3>
					</div>
				</div>
			</Tooltip>
		);
	}



	// Functionality ---------------------

	getPosition = position => {
		let newPosition = LEFT_POSITION;

		switch(position) {
			case LEFT_POSITION:
				newPosition = LEFT_POSITION;
				break;

			case RIGHT_POSITION:
				newPosition = RIGHT_POSITION;
				break;

			default:
				break;
		}

		return newPosition;
	};



	// Events ----------------------------

	// __handle = event => {};

	
}


const styles = theme => ({
	container: {
		opacity: 0,
		width: '100%',
		paddingTop: 30,
		height: '100%',
		display: 'flex',
		paddingBottom: 30,
		textAlign: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		transition: `opacity 750ms ${theme.transitions.easing.easeInOut}`,
	},
	contentCenter: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
	},

	showing: {
		opacity: 1,
	},

	[RIGHT_POSITION]: {
		paddingRight: 143,
		alignItems: 'flex-end',
	},
	[LEFT_POSITION]: {
		paddingLeft: 143,
		alignItems: 'flex-start',
	},

	iconContainer: {
		zIndex: 100,
		display: 'flex',
		overflow: 'hidden',
		position: 'relative',
		width: ICON_SIZE * 3,
		alignItems: 'center',
		height: ICON_SIZE * 3,
		justifyContent: 'center',
		backgroundSize: '100% 100%',
		borderRadius: ICON_SIZE * 1.5,
		// animation: 'SpinBackground 1s ease infinite',
		border: `1px double ${theme.palette.primary.contrastText}`,

		'&:before': {
			opacity: 0,
			zIndex: -100,
			content: `''`,
			top: 0, left: 0,
			display: 'block',
			position: 'absolute',
			width: ICON_SIZE * 3,
			height: ICON_SIZE * 3,
			borderRadius: ICON_SIZE * 1.5,
			transition: 'opacity 450ms linear',
		},

		'&:hover': {
			'&:before': {
				opacity: 1,
			},
		},
	},
	[`iconColor-${LEFT_POSITION}`]: {
		backgroundImage: getLinearGradient('210deg', [`${theme.palette.primary.dark} 35%`, `${theme.palette.secondary.light} 75%`]),

		'&:before': {
			backgroundImage: getLinearGradient('330deg', [`${theme.palette.primary.dark} 35%`, `${theme.palette.secondary.light} 75%`]),
		},
	},
	[`iconColor-${RIGHT_POSITION}`]: {
		backgroundImage: getLinearGradient('150deg', [`${theme.palette.primary.dark} 35%`, `${theme.palette.secondary.light} 75%`]),

		'&:before': {
			backgroundImage: getLinearGradient('30deg', [`${theme.palette.primary.dark} 35%`, `${theme.palette.secondary.light} 75%`]),
		},
	},
	icon: {
		marginBottom: 10,
		fontSize: ICON_SIZE,
		color: theme.palette.primary.contrastText,
	},

	title: {
		fontWeight: 600,
		fontSize: '2rem',
		fontFamily: `"Open Sans"`,
		color: theme.palette.primary.contrastText,
	},

	'@keyframes SpinBackground': { 
		'0%': {
			backgroundImage: getLinearGradient('210deg', [`${theme.palette.primary.dark} 35%`, `${theme.palette.secondary.light} 75%`]),
		},
		'50%': {
			backgroundImage: getLinearGradient('30deg', [`${theme.palette.primary.dark} 35%`, `${theme.palette.secondary.light} 75%`]),
		},
		'100%': {
			backgroundImage: getLinearGradient('180deg', [`${theme.palette.primary.dark} 35%`, `${theme.palette.secondary.light} 75%`]),
		},
	},
});


export default withStyles(styles)(ServiceBubble);

