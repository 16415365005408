/*
	~ Input Field Component
*/

import React from 'react';
import includeClassNames from 'classnames';

import { 
	Input,
	InputLabel,
	FormControl,
	FormHelperText,

} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	formControl: {
		minHeight: 80,
		marginTop: 10,
		display: 'flex',
		width: theme.fullWidthPercentage,
	},
});

export default withStyles(styles)(
	props => {
		const {
			id,
			type,
			input,
			classes,
			inputLabel,
			inputProps,
			labelComponent,
			helperText = '',
			required = false,
			autoFocus = false,
			isMultiline = false,
			meta: { error, touched },
			formControlClasses = null,

		} = props;
		
		const { 
			name, 
			value,
			onBlur,
			onFocus,
			onChange,

		} = input;

		return (
			<FormControl
				error={touched && !!error}
				className={
					includeClassNames({
						[classes.formControl]: true,
						[formControlClasses]: formControlClasses !== null,
					})
				}>

				{
					labelComponent || 
						<InputLabel htmlFor={id}>
							{ inputLabel }
						</InputLabel>
				}
				
				<Input
					id={id}
					name={name}
					type={type}
					onBlur={onBlur}
					onFocus={onFocus}
					required={required}
					onChange={onChange}
					autoFocus={autoFocus}
					multiline={isMultiline}
					value={type === 'file' ? '' : value}
					{...inputProps} />

				{ renderFormHelperText(touched, error, helperText) }
			</FormControl>
		);
	}
);


const renderFormHelperText = (touched, error, helperText) => {
	if(touched && !!error) {
		return ( <FormHelperText>{error}</FormHelperText> );
	
	} else if(!!helperText && helperText !== '' && helperText !== ' ') {
		return <FormHelperText>{helperText}</FormHelperText>
	
	} else {
		return null;
	}
};


