/*
	~ Houses all Mui Custom Theme Derivisions for Material UI Theme NPM Package
*/


import { createMuiTheme } from '@material-ui/core/styles';

import {
	GLOBAL_FONT,
	ACTION_COLORS,
	PRIMARY_COLORS,
	CENTRALIZED_BOX,
	SECONDARY_COLORS,
	CIRCULAR_DIAMETER,
	DEFAULT_SKEW_ANGLE,
	DEFAULT_BORDER_SIZE,
	NOTIFICATION_COLORS,
	DEFAULT_SLANT_ANGLE,
	BIG_SITE_LOGO_SQUARE,
	DEFAULT_BUTTON_HEIGHT,
	DEFAULT_BACKGROUND_COLOR,
	DEFAULT_EMPTY_SPACE_COLOR,
	DEFAULT_GLOBAL_NAVBAR_HEIGHT,
	
} from 'globalStyles';


export default createMuiTheme({
	palette: {
		background: {
			default: DEFAULT_BACKGROUND_COLOR
		},
		primary: { ...PRIMARY_COLORS },
		secondary: { ...SECONDARY_COLORS },
		error: { ...NOTIFICATION_COLORS },
		action: {
			disabled: ACTION_COLORS.disabled,
			disabledBackground: ACTION_COLORS.disabledBackground,
		},
		defaultBackgroundColor: DEFAULT_BACKGROUND_COLOR,
		galleryBoxBackgroundColor: DEFAULT_EMPTY_SPACE_COLOR,
	},

	// overrides: {
	// 	MuiButton: {},
	// 	MuiMenuItem: {},
	// 	MuiAppBar: {},
	// 	MuiToolbar: {},
	// 	MuiInput: {},
	// 	MuiNotchedOutline: {},
	// },

	zIndex: {},

	typography: {
		fontSize: 16,
		useNextVariants: true,
		fontFamily: GLOBAL_FONT,
	},

	sizes: {
		borderSize: DEFAULT_BORDER_SIZE,
		bigSiteLogo: BIG_SITE_LOGO_SQUARE,
		defaultNavbarHeight: DEFAULT_GLOBAL_NAVBAR_HEIGHT,

		circles: {
			xSmall: 30,
			small: (CIRCULAR_DIAMETER / 2),
			medium: (CIRCULAR_DIAMETER / 1.5),
			large: (CIRCULAR_DIAMETER / 1.25),
			xLarge: CIRCULAR_DIAMETER,
		},

		buttons: {
			defaultHeight: DEFAULT_BUTTON_HEIGHT,
		},
	},

	transformations: {
		slant: {
			forward: `rotate(${DEFAULT_SLANT_ANGLE})`,
			backward: `rotate(-${DEFAULT_SLANT_ANGLE})`,
		},
		skew: {
			backward: `skewX(${DEFAULT_SKEW_ANGLE}deg)`,
			forward: `skewX(-${DEFAULT_SKEW_ANGLE}deg)`,
		},
	},

	// Custom UI Style Templates
	centralizedBox: { ...CENTRALIZED_BOX },
});
