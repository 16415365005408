/*
	~ All Form Utility Functions
*/


// Form Validation ---------------------------------

// const hasANumber = /\d/;
// const hasALowercaseLetterRegex = /(?=.*?[a-z])/;
// const hasAnUppercaseLetterRegex = /(?=.*?[A-Z])/;
const isEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const email = value => isEmail.test(value) ? undefined : 'Incorrect format';
export const required = value => (value ? undefined : 'Required *');
export const maxChars = limit => value => (value.length <= limit ? undefined : `Must be less than ${limit} characters`);



// Form Data ---------------------------------------

export function getInitialEmailDialogFormData(initialType) {
	return {
		type: '',
		designer: '',
		material: '',
		mainContentInput: null,
		emailType: (initialType || ''),
	};
}


// export function getInitial[__NAME__]FormData() {
// 	return {};
// }
