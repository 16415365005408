/*
	~ Global Navbar Connector
*/

import { connect } from 'react-redux';
import { navigateTo } from 'reducers/App/actions';
import { ScrollPositionConsumer } from 'atoms/_Scroll';
import { openEmailDialog } from 'reducers/CustomerEmail/actions';

import React from 'react';
import GlobalNavbarView from './index.js';

const ScrollProvidingConnection = props => (
	<ScrollPositionConsumer>
		{
			({ windowPosition }) => ( <GlobalNavbarView windowPosition={windowPosition} { ...props } /> )
		}
	</ScrollPositionConsumer>
);

const mapStateToProps = ({
	app,
	router,

}) => ({
	appDetails: app.ui,
	routePath: router.location ? router.location.pathname : null,
});

const mapDispatchToProps = {
	navigateTo,
	openEmailDialog,
};


export default connect(mapStateToProps, mapDispatchToProps)(ScrollProvidingConnection);

