/*
	~ Bubble Styles
*/

import { DEFAULT_SIZES } from './index';
// import { getRGBAColor } from 'helpers/styleHelpers';

const populateFillActions = circles => {
	let actions = {};

	DEFAULT_SIZES.forEach(size => {
		actions[`fill-${size}`] = { transform: 'translate(0, 15px)' };
		actions[`empty-${size}`] = { transform: `translate(0, ${circles[size]}px)` };
	});
	
	return actions;
};

export default theme => ({
	container: {
		zIndex: 99,
		outline: 'none',
	    cursor: 'n-resize',
	    overflow: 'hidden',
		fontFamily: 'Open Sans',
		backfaceVisibility: 'hidden',
		color: theme.palette.primary.main,
	    transform: 'translate3d(0, 0, 0)',
		border: `10px double ${theme.palette.primary.dark}`,
		backgroundColor: theme.palette.primary.contrastText,
	},
	childContainer: {
		width: '100%',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	hiddenContent: {
		width: '100%',
		height: '100%',
		display: 'flex',
		paddingLeft: '15%',
		paddingRight: '15%',
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
	},
	hiddenContentText: {
		fontSize: '2em',
		fontFamily: 'Open Sans',
		color: theme.palette.primary.contrastText,
	},

	fill: {
		width: '100%',
		top: 0, left: 0,
		position: 'absolute',
		transition: 'transform 1s cubic-bezier(.2, .6, .6, .4)',
	},
	waveAnimation: {
	    animationDuration: '0.55s',
	    animationName: 'waveAction',
	    animationTimingFunction: 'linear',
	    animationIterationCount: 'infinite',
	},

	'@keyframes waveAction': {
	    '0%': {
	        transform: 'translate(-85%, 0)',
	    },
	    '100%': {
	        transform: 'translate(0, 0)',
	    },
	},

	...populateFillActions(theme.sizes.circles),
});

