/*
	~ Centralized API Action Creators
*/


import { RSAA } from 'redux-api-middleware';


export function buildFetchAction(type = null, endpoint = null, fetchProps = { headers: {} }) {
	if(type) {
		if(endpoint) {
			return {
				[RSAA]: {
					endpoint,
					method: type,
					...fetchProps,
				}
			};
		
		} else {
			console.log('Cannot fetch to endpoint without a URL, please provide URL for buildFetchAction() function...');
		}
	
	} else {
		console.log('Cannot fetch to endpoint without a fetch type, please provide a type (such as GET) for buildFetchAction() function');
	}
}