/*
	~ Page Not Found Styles
*/

import { ACTION_COLORS } from 'globalStyles';


export default theme => ({
	container: {
	    flex: 0.92,
	    width: '100%',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		background: ACTION_COLORS.disabled,
	},

	centralizedContent: {
		...theme.centralizedBox,
		marginTop: '10vh',
		marginBottom: '10vh',
		justifyContent: 'center',
	},

	errorTitle: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	errorIcon: {
		fontSize: 40,
		marginRight: 10,
	},
	errorSubTitle: {
		marginBottom: 0,
	},

	homeButton: {
		marginTop: 20,
	},
});

