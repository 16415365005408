/*
	~ User State Reducer & Actions
*/

import {
	ADD_MESSAGE_TO_NOTIFICATION_CENTER,
	REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER,

} from './actionTypes';

const initialState = {
	messages: [],
};


/* STATE REDUCER */

export default function reducer(state = initialState, action = {}) {

	switch(action.type) {
		case ADD_MESSAGE_TO_NOTIFICATION_CENTER:
			return {
				...state,
				messages: [
					...state.messages,
					{
						message: action.message,
						messageType: action.messageType,
					}
				],
			};

		case REMOVE_MESSAGE_FROM_NOTIFICATION_CENTER:
			const messages = [ ...state.messages ];
			messages.shift();

			return {
				...state,
				messages: [ ...messages ],
			};

		default: 
			return state;
	}

}

