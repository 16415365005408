/*
	~ Home Page View
*/

import React, { Component } from 'react';

import ServicesBubble, {
	LEFT_POSITION,
	RIGHT_POSITION,

} from 'atoms/_ServiceBubble';


import Styles from './styles';
import includeClasses from 'classnames';
import FlatButton from 'atoms/_FlatButton';
import RockBottom from 'atoms/_RockBottom';
import ScubaSteve from 'atoms/_ScubaSteve';
import TechnoBubble from 'atoms/_TechnoBubble';
import CompanyLogo from 'images/logo_small.PNG';
import WaterBubble from 'atoms/Bubble/bubbleContainer';
import EngMeetingImage from 'images/engineering_meeting.png';
import EngineeringTableApps from 'images/devs_table_apps.png';
import WaveVideo from 'assets/vid/waves_small_compressed.mp4';
import Navbar from 'molecules/GlobalNavbar/globalNavbarContainer';
import EngineeringBigScreenApps from 'images/devs_big_screen_apps.png';

import '@fortawesome/fontawesome-free/css/all.css';

import { withStyles } from '@material-ui/core/styles';

import { 
	Fab,
	Grid,
	CircularProgress,

} from '@material-ui/core';

import {
	Work,
	Update,
	DataUsage,
	PhoneIphone,
	ArrowUpward,
	DevicesOther,
	DesktopWindows,
	MonetizationOn,
	ContactMailOutlined,

} from '@material-ui/icons';

// import { getPathByRoute, ROUTES } from 'settings/routes';

const DIVER_WIDTH = 250;
const DIVER_HEIGHT = 750;
const FLIPPER_TOP_ANGLE = 15;
const SCUBA_LIGHT_STEP = 0.005;
const SCUBA_STEP_DISTANCE = 3.5;
const FLIPPER_BOTTOM_ANGLE = -15;
export const SERVICES_HEIGHT = 2200;


class HomePageView extends Component {
	static propTypes = {
		
	};

	static defaultProps = {
		
	};

	//rN Lifecycle ----------------------

	constructor(props) {
		super(props);

		this.lastFoundOffset = 0;
		this.aboutUsRef = React.createRef();
		this.servicesRef = React.createRef();
		this.treasureHuntRef = React.createRef();
		this.processSectionRef = React.createRef();
		this.getStarteedSectionRef = React.createRef();

		this.state = {
			leftFlipperAngle: 0,
			animateTreasureHunt: false,
			videoIsLoading: false,
			scubaLightDirection: true,
			scubaLightBrightness: 0.45,
			rightFlipperAngle: FLIPPER_BOTTOM_ANGLE,
			serviceBubbles: [false, false, false, false],
		};
	}


	componentDidUpdate(prevProps) {
		const { windowPosition } = this.props;

		if (windowPosition !== prevProps.windowPosition) {
			this.resolveScrollEvents();
		}
	}


	render() {
		const { classes } = this.props;

		return (
			<div className={classes.container}>
				<Navbar
					processOnCick={this.__handleNavbarProcessOnClick}
					aboutUsOnClick={this.__handleNavbarAboutUsOnClick}
					servicesOnCick={this.__handleNavbarServicesOnClick}
					getStartedOnClick={this.__handleNavbarGetStartedOnClick}
					 />

				{ this.renderVideoheader() }
				{ this.renderBubbles() }
				{ this.renderServices() }
				{ this.renderGetStarted() }
				{ this.renderEmptySpaceWindow() }
				{ this.renderProcess() }
				{ this.renderAboutUs() }
				{ this.renderSearchLightTreasure() }
				{ this.renderFooter() }
				
				<Fab 
					onClick={this.__handleBackToTopOnClick}
					classes={{ root: classes.backToTopButton }}>

					<ArrowUpward />
				</Fab>
			</div>
		);
	}


	renderVideoheader() {
		const { classes } = this.props;
		const { videoIsLoading } = this.state;

		return (
			<React.Fragment>
				{videoIsLoading && (
					<CircularProgress />
				)}

				<video
					loop
					muted
					autoPlay
					src={WaveVideo}
					preload={'auto'}
					type={'video/mp4'}
					className={classes.video}
					ref={ref => this.headerVideo}
					onLoadStart={() => {
						this.setState({ isLoading: true });
					}}
					onLoadedData={() => {
						this.setState({ isLoading: false });
					}}>
				</video>

				<div className={classes.videoOverlay}>
					<h1 className={classes.companyTitle}>
						NAUTILUS COVE TECHNOLOGIES
					</h1>

					<h3 className={classes.companyTagline}>
						Products Led by Qualitty
					</h3>
						
					<FlatButton 
						className={classes.whoAreWeButton} 
						onClick={this.__handleWhoAreWeButtonOnClick}>

						Dive In
					</FlatButton>
				</div>
			</React.Fragment>
		);
	}


	renderBubbles() {
		const { classes } = this.props;

		return (
			<div className={classes.descriptionContainer}>
				{ this.renderTopWave() }

				<Grid container spacing={0} classes={{ container: classes.bubblesContainer }}>
					<Grid item xs={12} md={4} classes={{ item: classes.pushRightBubbleColumn }}>
						{
							this.renderTripleBubble({
								top: -30,
							})
						}

						<WaterBubble
							shadow={2}
							size={'medium'}
							placements={{ directions: { top: 100 } }}
							hiddenContent={`We don't advertise we use word of mouth referrals`}>

							<MonetizationOn classes={{ root: classes.moneyIcon }} />

							<h3 className={classes.moneyTagline}>
								We don't
								<br />
								make you pay
								<br />
								for advertising
							</h3>
						</WaterBubble>
					</Grid>

					<Grid item xs={12} md={4} classes={{ item: classes.bubbleColumn }}>
						<WaterBubble 
							shadow={26} 
							size={'xLarge'}
							hiddenContent={`You're here because a friend told you about us`}>

							<img 
								src={CompanyLogo}
								className={classes.siteLogo}
								alt={'Nautilus Cove Site Logo'} />

							<h3 className={classes.companyDescription}>
								Nautilus Cove makes apps by word of mouth networking
							</h3>
						</WaterBubble>

						{ 
							this.renderTripleBubble({
								left: 120,
							})
						}
					</Grid>

					<Grid item xs={12} md={4} classes={{ item: classes.pushLeftBubbleColumn }}>
						{
							this.renderDoubleBubble({
								right: 50,
								position: 'absolute',
							})
						}

						<WaterBubble
							shadow={2}
							size={'large'}
							hiddenContent={'Money does not lead our decisions about the projects we choose to take on!'}>

							<div className={classes.iconBox}>
								<Work classes={{ root: classes.workIcon }} />
								<Update classes={{ root: classes.clockIcon }} />
							</div>

							<h3 className={classes.experienceTagline}>
								We choose to 
								<br />
								build apps that
								<br />
								<em>we love</em>
							</h3>
						</WaterBubble>
					</Grid>
				</Grid>

				{ this.renderBottomWave() }
			</div>
		);
	}


	renderTopWave(primary = true) {
		const { classes } = this.props;

		return (
			<div className={classes.topWaveContainer}>
				<svg 
					viewBox={'0 0 500 150'} 
					preserveAspectRatio={'none'}
					className={
						includeClasses({
							[classes.wave]: true,
							[classes.primaryColor]: primary,
							[classes.secondaryColor]: !primary,
						})
					}>

					<path d={'M0.00,49.98 C143.90,144.56 143.90,16.28 500.00,49.98 L500.00,150.00 L0.00,150.00 Z'}></path>
				</svg>
			</div>
		);
	}


	renderBottomWave(primary = true) {
		const { classes } = this.props;

		return (
			<div className={classes.bottomWaveContainer}>
				<svg 
					viewBox={'0 0 500 150'} 
					preserveAspectRatio={'none'}
					className={
						includeClasses({
							[classes.wave]: true,
							[classes.primaryColor]: primary,
							[classes.secondaryColor]: !primary,
						})
					}>

					<path d={'M-29.91,54.77 C97.06,214.63 390.52,-91.28 505.07,63.64 L500.00,0.00 L0.00,0.00 Z'}></path>
				</svg>
			</div>
		);
	}


	renderTripleBubble(style) {
		const { classes } = this.props;

		return (
			<div className={classes.tripleBubble} style={style}>
				<div />
				<div />
				<div />
			</div>
		);
	}


	renderDoubleBubble(style) {
		const { classes } = this.props;
		const { scubaLightBrightness } = this.state;

		return (
			<div className={classes.doubleBubble} style={style}>
				<div style={{ position: 'relative', left: scubaLightBrightness }} />
				<div style={{ position: 'relative', right: scubaLightBrightness }} />
			</div>
		);
	}


	renderServices() {
		const { classes } = this.props;
		
		const {
			showingSteve,
			leftFlipperAngle,
			rightFlipperAngle,
			scubaLightBrightness,

		} = this.state;
		
		return (
			<div className={classes.servicesContainer} ref={this.servicesRef}>
				{showingSteve && (
					<ScubaSteve
						width={DIVER_WIDTH}
						height={DIVER_HEIGHT}
						leftFlipperAngle={leftFlipperAngle}
						rightFlipperAngle={rightFlipperAngle}
						lightBrightness={scubaLightBrightness}
						depth={(window.innerHeight / 2) - (DIVER_HEIGHT / 2)} />
				)}

				<h2 
					className={classes.sectionTitle}
					style={{
						top: 300,
						right: '10%',
						position: 'absolute',
					}}>

					<DataUsage classes={{ root: classes.titleIcon }} />
					Services
				</h2>

				{ 
					this.renderServiceBubble(
						0,
						RIGHT_POSITION, 
						PhoneIphone, 
						(
							<React.Fragment>
								Mobile
								<br />
								Applications
								
								{
									this.renderTripleBubble({
										top: 25,
										left: 143,
									}) 
								}
							</React.Fragment>
						),
						`We make iOS and Android applications that are natively designed or themed with a branded design`
					)
				}
				{ 
					this.renderServiceBubble(
						1,
						LEFT_POSITION, 
						DesktopWindows, 
						(
							<React.Fragment>
								Web 
								<br />
								Applications
							</React.Fragment>
						),
						`We build CMS managed marketing sites, personal sites and client portal web applications used for business`
					)
				}
				{
					this.renderTripleBubble({
						top: 25,
						left: 110,
					}) 
				}
				{ 
					this.renderServiceBubble(
						2,
						RIGHT_POSITION, 
						DevicesOther, 
						(
							<React.Fragment>
								Enterprise
								<br />
								& Systems
								<br />
								Software 
							</React.Fragment>
						),
						`We build cloud solutions that scale for use in application development as well as for use in deployment pipelines`
					)
				}
				{
					this.renderDoubleBubble({
						right: 10,
					}) 
				}
				{
					this.renderTripleBubble({
						top: 0,
						left: 110,
					}) 
				}
				{ 
					this.renderServiceBubble(
						3,
						LEFT_POSITION, 
						ContactMailOutlined, 
						(
							<React.Fragment>
								Credentialing
								<br />
								& Provisioning
							</React.Fragment>
						),
						`We build fullscale business systems designed to work in warehouses, live events, airlines and many more business solutions`
					)
				}
			</div>
		);
	}


	renderServiceBubble(id, position = LEFT_POSITION, icon, component = null, tooltip = '') {
		const { serviceBubbles } =this.state;

		return (
			<Grid container spacing={32}>
				{ position === LEFT_POSITION && (<Grid item xs={12} sm={6}></Grid>) }

				<Grid item xs={12} sm={6}>
					<span ref={ref => this[`service_bubble_${id}`] = ref}>
						<ServicesBubble 
							position={position} 
							IconComponent={icon} 
							tooltipContent={tooltip}
							showing={serviceBubbles[id]}
							bodyTextComponent={component} />
					</span>
				</Grid>
			</Grid>
		);
	}


	renderGetStarted() {
		const { classes } = this.props;

		return (
			<div className={classes.processContainer}>
				{ this.renderTopWave(false) }
				
				<div className={classes.rockContainer}>
					<RockBottom 
						className={
							includeClasses({
								[classes.rockBottom]: true,
								[classes.topRock]: true,
							})
						} />
					
					<RockBottom 
						flip={true}
						className={
							includeClasses({
								[classes.rockBottom]: true,
								[classes.bottomRock]: true,
							})
						} />
				</div>

				<div ref={ref => this.getStarteedSectionRef = ref} className={classes.processTextContent}>
					<h2 className={classes.sectionTitle} style={{ marginBottom: 200 }}>
						<DataUsage classes={{ root: classes.titleIcon }} />
						Get Started With Us!
					</h2>

					<Grid container spacing={0}>
						<Grid item xs={12} md={4} classes={{ item: classes.processColumn }}>
							<TechnoBubble 
								width={400}
								height={400}
								title={'STEP 1'}
								subtitle={'Reach Out!'}>
								<p>
									Either, send us an email at 
									<a href="mailto: info@nautiluscove.ca">info@nautiluscove.ca</a>, 
									or click "Contact Us" and send us your vision
								</p>
							</TechnoBubble>
						</Grid>

						<Grid item xs={12} md={4} classes={{ item: classes.processColumn }}>
							<TechnoBubble 
								width={500}
								height={500}
								title={'STEP 2'}
								subtitle={`Vision Debriefing`}>
								<p>
									Once we have discussed <strong>your vision</strong> across the internet, 
									it's time to meet with us and discuss how we can make your vision come to life
								</p>
							</TechnoBubble>
						</Grid>

						<Grid item xs={12} md={4} classes={{ item: classes.processColumn }}>
							<TechnoBubble 
								title={'STEP 3'}
								subtitle={'Quote'}>
								<p>
									After your vision has been received and put to planning, we discuss the efforts involved
								</p>
							</TechnoBubble>
						</Grid>
					</Grid>
				</div>

				{ this.renderBottomWave(false) }
			</div>
		);
	}

	renderEmptySpaceWindow() {
		const { classes } = this.props;

		return (
			<div className={classes.emptySpaceContainer}>
				{ this.renderTripleBubble({ left: 143 }) }
				{ this.renderDoubleBubble({ right: 143 }) }
				{ this.renderTripleBubble({ left: -143 }) }
			</div>
		);
	}
	
	renderProcess() {
		const { classes } = this.props;

		return (
			<div className={classes.processContainer}>
				{ this.renderTopWave(false) }

				<div ref={ref => this.processSectionRef = ref} className={classes.processTextContent}>
					<h2 className={classes.sectionTitle} style={{ marginBottom: 200 }}>
						<DataUsage classes={{ root: classes.titleIcon }} />
						Our process
					</h2>

					<Grid container spacing={0}>
						<Grid item xs={12} md={4} classes={{ item: classes.processColumn }}>
							<TechnoBubble 
								width={400}
								height={400}
								title={'PHASE 1'}
								subtitle={'Research'}>
								<p>
									We <strong><em>enjoy</em></strong> finding out what you really want 
									and we're <strong><em>not afraid</em></strong> to put in the time to find out!
								</p>
							</TechnoBubble>
						</Grid>

						<Grid item xs={12} md={4} classes={{ item: classes.processColumn }}>
							<TechnoBubble 
								width={500}
								height={500}
								title={'PHASE 2'}
								subtitle={`Development & Demo`}>
								<p>
									During our development phase we meet with you (on average) once a week
									to show you the product and ensure that it's meeting <strong><em>your 
									expectations</em></strong> and <strong><em>demands</em></strong>.
								</p>
							</TechnoBubble>
						</Grid>

						<Grid item xs={12} md={4} classes={{ item: classes.processColumn }}>
							<TechnoBubble 
								title={'PHASE 3'}
								subtitle={'Client Testing'}>
								<p>
									We do not move to completion <strong><em>without 
									your testing</em></strong> of the product and have
									agreed that it meets all of <strong><em>your expectations</em></strong>.
								</p>
							</TechnoBubble>
						</Grid>
					</Grid>
				</div>

				{ this.renderBottomWave(false) }
			</div>
		);
	}


	renderAboutUs() {
		const { classes } = this.props;

		return (
			<div className={classes.aboutUs} ref={ref => this.aboutUsRef = ref}>
				<h2 className={classes.aboutUsTitle} style={{ marginBottom: 200 }}>
					<DataUsage classes={{ root: classes.titleIcon }} />
					About Us
				</h2>

				{
					this.renderAboutRow(
						'Our Vision',
						(
							<>
								<p style={{color: 'black'}}>
									We believe in cutting costs to our end users by using the opportunity to create code 
									for them (within a new project) that we can modularize, componentize and have for use 
									in future projects for all clients.
								</p>
								<p style={{color: 'black'}}>
									When we create a new application, we can immediately leverage all past components and 
									modules that we have created in all past projects. These components and modules are 
									battle tested in production, easy to leverage development hours upon and they have been 
									created with a full suite of tests to be able to ship faster.
								</p>
								<p style={{color: 'black'}}>
									As a result, we have a high success rate in fast prototyping features to the market 
									using <strong><em>"Product Market Fit"</em></strong> for market feedback.
								</p>
							</>
						),
						EngMeetingImage,
						true
					)
				}
				{
					this.renderAboutRow(
						'How We Met',
						(
							<>
								<p style={{color: 'black'}}>
									We are a team of Engineers that have met throughout our careers while working for a 
									variety of different companies of all sizes and statuses. 
								</p>
								<p style={{color: 'black'}}>
									Each of our team members possesses a unique piece of the puzzle that makes the spread 
									of our available skillset(s) so wide.
								</p>
								<p style={{color: 'black'}}>
									As a result, we have each met each other through means of referral via our present engineers.
								</p>
							</>
						),
						EngineeringTableApps
					)
				}
				{
					this.renderAboutRow(
						'Who are we?',
						(
							<>
								<p style={{color: 'black'}}>
									Nautilus Cove Technologies does not have any central Head Quarters located in a specific 
									real-world location (as of yet). All of the Engineers working at Nautilus Cove work 
									remotely so there has been no need to create a physical workplace so far...
								</p>
								<p style={{color: 'black'}}>
									We are a fully remote team of engineers, managers, designers and developers. Our most senior 
									engineer has over 30 years of industry experience and our least senior team member has 10 
									years of industry experience.
								</p>
								<p style={{color: 'black'}}>
									Most of our staff hails from Nova Scotia, Canada.
								</p>
							</>
						),
						EngineeringBigScreenApps,
						true
					)
				}
			</div>
		);
	}


	renderAboutRow(title, content, image, leftContent = false) {
		return (
			<Grid container spacing={12} style={{ marginBottom: '8vh' }}>
				{
					leftContent && (
						<Grid item xs={12} md={6}>
							<h1>
								{ title }
							</h1>

							{ content }
						</Grid>
					)
				}

				<Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<img
						src={image} 
						style={{ width: '55%', height: 'auto', borderRadius: '50%' }} />
				</Grid>

				{
					leftContent === false && (
						<Grid item xs={12} md={6}>
							<h1>
								{ title }
							</h1>

							{ content }
						</Grid>
					)
				}
			</Grid>
		);
	}


	renderSearchLightTreasure() {
		const { classes } = this.props;
		const { animateTreasureHunt } = this.state;

		return (
			<div className={classes.treasureHunt} ref={ref => this.treasureHuntRef = ref}>
				{ this.renderTopWave(false) }

				<div 
					className={
						includeClasses({
							[classes.searchlightBox]: true,
							[classes.animatedSearchLight]: animateTreasureHunt,
						})
					}>
					<div className={classes.sandyBottom} style={{ alignSelf: 'flex-end' }} />
				</div>

				{ this.renderBottomWave(false) }
			</div>
		);
	}


	renderFooter() {
		const { classes } = this.props;

		return (
			<div className={classes.footer}>
				<h2 className={classes.footerTitle}>
					<DataUsage classes={{ root: classes.titleIcon }} />
					Made by Nautilus Cove Technologies
				</h2>

				<Grid container spacing={0} style={{ marginBottom: '8vh' }}>
					<Grid item xs={12} md={4}>

					</Grid>

					<Grid item xs={12} md={4}>

					</Grid>

					<Grid item xs={12} md={4}>

					</Grid>
				</Grid>
			</div>
		);
	}



	//Functionality ---------------------

	resolveScrollEvents() {
		const { windowPosition } = this.props;
		
		const { 
			serviceBubbles,
			leftFlipperAngle, 
			rightFlipperAngle, 
			scubaLightDirection,
			scubaLightBrightness,

		} = this.state;

		// Half the window + half of steve = tip of his flashlight
		const triggerPoint = (window.innerHeight / 1.75) + (DIVER_HEIGHT / 2);

		let showingSteve = true;
		let animateTreasureHunt = false;
		let flipBrightnessDirection = false;
		let newBrightness = scubaLightBrightness;
		
		if (scubaLightDirection) {
			if (scubaLightBrightness + SCUBA_LIGHT_STEP > 1) {
				flipBrightnessDirection = true;
			
			} else {
				newBrightness = (scubaLightBrightness + SCUBA_LIGHT_STEP > 1) ? 0.35 : scubaLightBrightness + SCUBA_LIGHT_STEP;
			}
		
		} else {
			if (scubaLightBrightness - SCUBA_LIGHT_STEP <= 0.35) {
				flipBrightnessDirection = true;
			
			} else {
				newBrightness = (scubaLightBrightness - SCUBA_LIGHT_STEP <= 0.35) ? 0.35 : scubaLightBrightness - SCUBA_LIGHT_STEP;
			}
		}

		let newServiceBubbles = [ ...serviceBubbles ];
		let distTravelled = this.lastFoundOffset - windowPosition;
		if (distTravelled < 0) distTravelled = (distTravelled * -1);
		if (distTravelled > SCUBA_STEP_DISTANCE) distTravelled = SCUBA_STEP_DISTANCE;

		if (serviceBubbles.length > 0) {
			serviceBubbles.forEach((bubble, bubbleIndex) => {
				if (this[`service_bubble_${bubbleIndex}`] && 
						triggerPoint >= this[`service_bubble_${bubbleIndex}`].getBoundingClientRect().y) {
					
					newServiceBubbles[bubbleIndex] = true;
				
				} else {
					newServiceBubbles[bubbleIndex] = false;
				}
			});
		}

		if (triggerPoint > this.aboutUsRef.getBoundingClientRect().y) showingSteve = false;
		if (triggerPoint > this.treasureHuntRef.getBoundingClientRect().y) animateTreasureHunt = true;

		this.setState({
			showingSteve,
			animateTreasureHunt,
			serviceBubbles: newServiceBubbles,
			scubaLightBrightness: newBrightness,
			leftFlipperAngle: this.getAngleByDistance(leftFlipperAngle, distTravelled),
			rightFlipperAngle: this.getAngleByDistance(rightFlipperAngle, distTravelled),
			scubaLightDirection: flipBrightnessDirection ? !(scubaLightDirection) : scubaLightDirection,
		});

		this.lastFoundOffset = window.pageYOffset;
	};
	

	getAngleByDistance(flipperAngle = 0, distance = 0) {
		let currAngle = flipperAngle + distance;
		if (currAngle >= FLIPPER_TOP_ANGLE) currAngle = FLIPPER_BOTTOM_ANGLE;

		return currAngle;
	}



	//Events ----------------------------

	__handleWhoAreWeButtonOnClick = event => {
		if (this.servicesRef) {
			this.servicesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};
	
	__handleNavbarServicesOnClick = event => {
		if (this.servicesRef) {
			this.servicesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};
	
	__handleNavbarAboutUsOnClick = event => {
		if (this.aboutUsRef) {
			this.aboutUsRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};
	
	__handleNavbarProcessOnClick = event => {
		if (this.processSectionRef) {
			this.processSectionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};
	
	__handleNavbarGetStartedOnClick = event => {
		if (this.getStarteedSectionRef) {
			this.getStarteedSectionRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	};

	__handleBackToTopOnClick = event => {
		window.scrollTo(0, 0);
	};
}


export default withStyles(Styles, { withTheme: true })(HomePageView);

