/*
	~ App Reducer Actions
*/

import { push } from 'react-router-redux';
import { openNotificationCenterAC } from 'reducers/NotificationCenter/actions';
import { MESSAGE_TYPE_SUCCESS, MESSAGE_TYPE_ERROR } from 'reducers/NotificationCenter/actionTypes';

import { 
	NETWORK_STATUS_ONLINE,
	SETTING_NETWORK_STATUS, 
	NETWORK_STATUS_OFFLINE,

} from './actionTypes';



/* ACTION CREATORS */

export function __settingNetworkStatusAC() {
	return { type: SETTING_NETWORK_STATUS }
}


export function __setNetworkStatusToConnectedAC() {
	return {
		value: true,
		type: NETWORK_STATUS_ONLINE,
	}
}


export function __setNetworkStatusToDisconnectedAC() {
	return {
		value: false,
		type: NETWORK_STATUS_OFFLINE,
	}
}



/* STATE MANIPULATION */

export function navigateTo(route) {
	return dispatch => {
		dispatch( push(route) );
	};
}


// This function is intended to load all remote data to local store
export function initializeApplication() {
	return dispatch => {
		//Exterior Global Listeners
		window.addEventListener('online',  () => { dispatch( setAndNotifyNetworkConnectivityStatus(true) ) } );
		window.addEventListener('offline',  () => { dispatch( setAndNotifyNetworkConnectivityStatus() ) } );

		return Promise.all([
			

		]).then().catch(error => {
			console.log('Found an error while trying to load all remote stores, File: reducers/App/actions, Function: initializeApplication()');
		});
	};
}


export function setNetworkConnectivityStatus(networkStatus = false) {
	return dispatch => {
		dispatch( __settingNetworkStatusAC() );
		networkStatus ? dispatch( __setNetworkStatusToConnectedAC() ) : dispatch( __setNetworkStatusToDisconnectedAC() );
	}
}


export function setAndNotifyNetworkConnectivityStatus(networkStatus = false) {
	return dispatch => {
		dispatch( __settingNetworkStatusAC() );

		if(networkStatus) {
			dispatch( __setNetworkStatusToConnectedAC() );
			dispatch(
				openNotificationCenterAC({ message: 'Application Online', messageType: MESSAGE_TYPE_SUCCESS })
			);

		} else {
			dispatch( __setNetworkStatusToDisconnectedAC() );
			dispatch(
				openNotificationCenterAC({ message: 'Application is offline. Some features may no longer work.', messageType: MESSAGE_TYPE_ERROR })
			);
		}
	}
}

