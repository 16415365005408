/*
	~ All Known Static Paths for Each Given Route

	Keys are intended to be used 
*/


//@Route Components [Absolute Paths] - Ordered By Flow Chronology
import HOME_VIEW from 'views/HomePage/homePageContainer';
import PAGE_NOT_FOUND_VIEW from 'views/PageNotFound/pageNotFoundContainer';
//==



//Route Key Names - Ordered By Flow Chronology
export const ROUTES = {
	HOME: 'HOME_ROUTE_KEY',
	PAGE_NOT_FOUND: 'PAGE_NOT_FOUND_ROUTE_KEY',
};

export const getPathByRoute = routeKey => {
	let path = ROUTE_OBJS[ROUTES.PAGE_NOT_FOUND].path;

	if (routeKey) {
		const route = routeKey.toUpperCase();

		if (Object.values(ROUTES).includes(route)) {
			path = ROUTE_OBJS[route].path;
		}
	}

	return path;
};
//==



//Route Objects - Ordered By Flow Chronology
export const ROUTE_OBJS = {

	// Dynamic Paths (requires URL)
	// [ROUTES.SINGLE_ITEM]: {
	// 	path: '/gallery/:id',
	// 	component: SINGLE_ITEM_VIEW,
	// },

	// Static Paths
	[ROUTES.HOME]: {
		path: '/',
		component: HOME_VIEW,
	},
	[ROUTES.PAGE_NOT_FOUND]: {
		path: null,
		component: PAGE_NOT_FOUND_VIEW,
	},
};

