/*
	~ All Dialog Constants
*/


// Email Dialogs

export const CUSTOM = {
	label: 'Custom',
	value: 'custom_key',
};

export const REPAIR = {
	label: 'Repair',
	value: 'repair_key',
};

export const OTHER = {
	label: 'Other',
	value: 'other_key',
};

export const EMAIL_TYPES = [
	CUSTOM,
	REPAIR,
	OTHER,
];

export const getEmailSubjectByType = type => {
	let subject = 'FireWorks Webmail';

	switch(type) {
		case CUSTOM.value:
			subject = 'Custom Piece Inquiry';
			break;

		case REPAIR.value:
			subject = 'Repair Work Inquiry';
			break;

		case OTHER.value:
			subject = 'Generic Customer Inquiry';
			break;

		default:
			break;
	}

	return subject;
};