/*
	~ Global Navbar View
*/

import React, { Component } from 'react';

import { formatDate } from 'helpers/dateTime';
import { withStyles } from '@material-ui/core/styles';
import { ROUTES, getPathByRoute } from 'settings/routes';

import { 
	AppBar,
	Toolbar,
	
} from '@material-ui/core';

import Styles from './styles';
import PropTypes from 'prop-types';
import includeClasses from 'classnames';
import FlatButton from 'atoms/_FlatButton';
import NavLink from 'atoms/NavLink/navLinkContainer';
import CompanyLogo from 'images/logo_small_grey.PNG';

// import MenuIcon from '@material-ui/icons/Menu';


class GlobalNavbarView extends Component {

	static propTypes = {
		navigateTo: PropTypes.func,
		processOnCick: PropTypes.func,
		servicesOnCick: PropTypes.func,
		aboutUsOnClick: PropTypes.func,
		getStartedOnClick: PropTypes.func,
		appDetails: PropTypes.object.isRequired,
	};

	static defaultProps = {
		processOnCick: () => {},
		servicesOnCick: () => {},
		aboutUsOnClick: () => {},
		getStartedOnClick: () => {},
	};


	//rN Lifecycle ----------------------

	constructor(props) {
		super(props);

		this.state = {
			activeMenuIndex: 0,
		};
	}

	
	render() {
		const {
			classes, 
			routePath,
			appDetails,
			processOnCick,
			windowPosition,
			servicesOnCick,
			aboutUsOnClick,
			getStartedOnClick,

		} = this.props;

		const activeToolbar = (windowPosition > 40);

		console.log(`process.env.NODE_ENV: ??????------------!!!!!!!!   `, process.env.NODE_ENV);

		return (
			<AppBar 
				color={'default'}
				position={'static'} 
				classes={{ 
					root: includeClasses({
						[classes.appBar]: true,
						[classes.activeToolbar]: activeToolbar,
					})
				}}>

				<Toolbar classes={{ root: classes.titleBar }}>
					{/*
							!! CAUTION !!
						THIS IS FOR DEVELOPMENT ONLY!
							REMOVE IN PROD
					*/}
					{
						process.env.NODE_ENV !== 'production' && appDetails && (
							<small className={
								includeClasses({
									[classes.developmentBox]: true,
									[classes.activeToolbarVersion]: activeToolbar,
								})}>

								v. {appDetails.version}, Released - {formatDate(new Date(appDetails.build))}
							</small>
						)
					}
					{/*
						//---------
					*/}

					<div className={classes.toolbarContent}>
						<div className={classes.toolbarDetails}>
							<a 
								href={'/'} 
								alt={'Main Logo'}
								onClick={this.__handleMainLogoClick}>

								<img 
									src={CompanyLogo}
									alt={'FireWorks Site Logo'} 
									className={classes.siteLogo} />
							</a>
						</div>

						<div className={classes.menu}>
							<NavLink
								className={includeClasses({ [classes.menuItem]: true })}
								onClick={this.__handleNavLinkOnClick(servicesOnCick)}
	  							isActive={routePath === getPathByRoute(ROUTES.PAGE_NOT_FOUND)}>

	  							Services
							</NavLink>

							<NavLink
								onClick={this.__handleNavLinkOnClick(getStartedOnClick)}
								className={includeClasses({ [classes.menuItem]: true })}
	  							isActive={routePath === getPathByRoute(ROUTES.PAGE_NOT_FOUND)}>

	  							Get Started
							</NavLink>
							
							<NavLink
								onClick={this.__handleNavLinkOnClick(processOnCick)}
								className={includeClasses({ [classes.menuItem]: true })}
	  							isActive={routePath === getPathByRoute(ROUTES.PAGE_NOT_FOUND)}>

	  							Our Process
							</NavLink>

							<NavLink
								onClick={this.__handleNavLinkOnClick(aboutUsOnClick)}
								className={includeClasses({ [classes.menuItem]: true })}
	  							isActive={routePath === getPathByRoute(ROUTES.PAGE_NOT_FOUND)}>

	  							About Us
							</NavLink>

							<FlatButton 
								className={classes.contactUsButton} 
								onClick={this.__handleContactUsButtonOnClick}>

								Contact Us!
							</FlatButton>
						</div>
					</div>
				</Toolbar>
			</AppBar>
		);
	}



	//Functionality ---------------------



	//Events ----------------------------

	__handleMainLogoClick = event => {
		const { navigateTo } = this.props;

		event.preventDefault();
		event.stopPropagation();

		if (navigateTo) navigateTo( getPathByRoute(ROUTES.HOME) );
	};

	__handleNavLinkOnClick = onClickCallback => event => {
		if (onClickCallback) {
			onClickCallback(event);
		}
	};

	__handleContactUsButtonOnClick = (event) => {
		const { openEmailDialog } = this.props;

		if (openEmailDialog) {
			openEmailDialog();
		}
	};
}


export default withStyles(Styles)(GlobalNavbarView);

