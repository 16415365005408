/*
	~ Application Container Styles
*/

// import { ACTION_COLORS } from 'globalStyles';


export default theme => ({
	container: {
		width: '100%',
		background: 'transparent',
	},
});

