/*
	~ This file will setup the entire local storage initial properties and config
*/


import moment from 'moment';

import { UI_DETAILS } from 'constants/localStorageKeys';

// eslint-disable-next-line
const UI_BUILD = __UI_BUILD__;
// eslint-disable-next-line
const UI_VERSION = __UI_VERSION__;

export default function setup() {
	if(localStorage.getItem(UI_DETAILS)) {
		const details = JSON.parse( localStorage.getItem(UI_DETAILS) );

		/* Reset storage if the version build date has increased */
		if(moment(details.build).diff(UI_BUILD, 'seconds') !== 0) {
			localStorage.clear(); // <-- RESET!
			console.log('Cleared all local storage!');

			// Add new item with new date
			localStorage.setItem(
				UI_DETAILS, 
				JSON.stringify({
					build: UI_BUILD,
					version: UI_VERSION,
				})
			);
		}

	} else {
		// clear just in-case
		localStorage.clear();
		console.log('Cleared all local storage!');

		localStorage.setItem(
			UI_DETAILS, 
			JSON.stringify({
				build: UI_BUILD,
				version: UI_VERSION,
			})
		);
	}
};

